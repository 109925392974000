import { useContext, useState } from "react";
import DateModalAnalise from "../../../../../components/Modal/ModalArm/DateModalAnalise/DateModalAnalise";
import { Container } from "./style";
import {
  lastSevenDayFormated,
  dayTodayFormated,
  firstMonthDayFormated,
  lastMonthDayFormated,
} from "../../../../../utils/dates";
import calendarThirty from "../../../../../assets/Icons Analise/calendarThirty.svg";
import calendarSeven from "../../../../../assets/Icons Analise/calendarSeven.svg";
import ButtonCalendar from "../../../../../components/Layouts/ButtonCalendar/ButtonCalendar";
import { AnaliseContext } from "../../../../../contexts/Analise/AnaliseContext";
// import calendarAtt from "../../../../../assets/Icons Analise/calendarReset.svg";

export function HeaderAnalise({ title }) {
  const { date, setDate } = useContext(AnaliseContext);

  const [statusModalCalendar, setStatusModalCalendar] = useState(false);
  const [hiddenCalendar, setHiddenCalendar] = useState();

  const { dataAnaliseInicio, dataAnaliseFinal } = date; // usar na api e no periodo

  // função para mostrar o modal do calendário
  const handleShowModalCalendar = () => {
    setStatusModalCalendar(true);
    setHiddenCalendar(true);
  };

  // função para fechar o modal do calendário
  const handleRequestCloseCalendar = () => {
    setHiddenCalendar(false);
  };

  function displayDateCalendar() {
    if (dataAnaliseInicio === lastSevenDayFormated || !dataAnaliseInicio) {
      return <span>Últimos 7 dias</span>;
    } else if (
      dataAnaliseInicio === firstMonthDayFormated ||
      !dataAnaliseInicio
    ) {
      return <span>Mensal</span>;
    } else {
      return (
        <span>
          De {dataAnaliseInicio} até {dataAnaliseFinal}
        </span>
      );
    }
  }

  return (
    <Container>
      <DateModalAnalise
        handleShowModalCalendar={statusModalCalendar}
        handleRequestCloseCalendar={handleRequestCloseCalendar}
        hiddenCalendar={hiddenCalendar}
        setHiddenCalendar={setHiddenCalendar}
      />
      <h2>{title}</h2>

      <div className="analises-calendar">
        <button
          onClick={() =>
            setDate({
              dataAnaliseInicio: firstMonthDayFormated,
              dataAnaliseFinal: lastMonthDayFormated,
            })
          }
          className="buttonLast30"
          title={`${firstMonthDayFormated} a ${lastMonthDayFormated}`}
        >
          <img src={calendarThirty} alt="" />
        </button>
        <button
          onClick={() =>
            setDate({
              dataAnaliseInicio: lastSevenDayFormated,
              dataAnaliseFinal: dayTodayFormated,
            })
          }
          className="buttonLast7"
          title={`${lastSevenDayFormated} a ${dayTodayFormated}`}
        >
          <img src={calendarSeven} alt="" />
        </button>
        {/* <button
                onClick={() =>
                  analise === "Processos Finalizados" ? fetchApiPorData() : fetchApiAnual()
                }
                className="buttonAtt"
                title={"Atualizar"}
              >
                <img src={calendarAtt} alt="" />
              </button> */}
        <ButtonCalendar handleShowModalCalendar={handleShowModalCalendar} />
        <div className="analises-dates">
          <span>Período:</span>
          {displayDateCalendar()}
        </div>
      </div>
    </Container>
  );
}
