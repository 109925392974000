import { useMemo } from "react";
import ChartTotal from "../../../../../../../components/Charts/ChartArm/ChartAnalises/ChartTotal";

export function ChartDay({ data, widthSize }) {
  const dataSets = useMemo(() => {
    if (!data.length) return { datasets: [], labels: [] };

    const total = {
      cacamba: [],
      graneleiro: [],
      outros: [],
    };
    const labels = [];

    let cacamba = 0;
    let graneleiro = 0;
    let outros = 0;

    data.forEach((item, index, array) => {
      const dateItem = new Date(item.DAT_PESO_INI).toLocaleDateString("pt-BR");
      const nextDate =
        index < array.length - 1
          ? new Date(array[index + 1].DAT_PESO_INI).toLocaleDateString("pt-BR")
          : null;

      if (item.NUM_CACAMBA_CAM === 1) cacamba += item.NUM_SACAS;
      if (item.NUM_GRANELEIRO_CAM === 1) graneleiro += item.NUM_SACAS;
      if (item.NUM_OUTROS_CAM === 1) outros += item.NUM_SACAS;

      if (dateItem !== nextDate || index === array.length - 1) {
        labels.push(dateItem);
        total.cacamba.push(cacamba);
        total.graneleiro.push(graneleiro);
        total.outros.push(outros);

        // Reinicializa as variáveis para o próximo ciclo
        cacamba = 0;
        graneleiro = 0;
        outros = 0;
      }
    });

    return {
      labels,
      datasets: [
        {
          type: "bar",
          label: "Caçamba",
          backgroundColor: "#1ecfd6",
          data: total.cacamba,
        },
        {
          type: "bar",
          label: "Graneleiro",
          backgroundColor: "#003d73",
          data: total.graneleiro,
        },
        {
          type: "bar",
          label: "Outros",
          backgroundColor: "#c05640",
          data: total.outros,
        },
      ],
    };
  }, [data]);

  return (
    <ChartTotal
      datasetGraphic={dataSets}
      height="220"
      width="1280"
      titleTooltip="Diário"
      displayDataLabel={false}
      responsive={widthSize}
    />
  );
}
