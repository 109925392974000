import { useMemo } from "react";

import { Container } from "./style";

import iconBag from "../../../../../../assets/Icons Analise/icon-bag.svg";
import iconDoc from "../../../../../../assets/Icons Analise/icon-doc.svg";
import iconAverage from "../../../../../../assets/Icons Analise/icon-average.svg";

import CardProcessosFinalizados from "../../../../../../components/Cards/CardArmAnalises/CardProcessosFinalizados/CardProcessosFinalizados";

export function Cards({ data }) {
  const dataAnalytic = useMemo(() => {
    if (data.length === 0) {
      return { totalSacas: 0, totalDocs: 0, media: 0 };
    }

    const { totalSacas, totalDocs } = data.reduce(
      (acc, item) => {
        acc.totalSacas += item.NUM_SACAS;
        acc.totalDocs += 1;
        return acc;
      },
      { totalSacas: 0, totalDocs: 0 } // Valor inicial do acumulador
    );

    return {
      totalSacas,
      totalDocs,
      media: (totalSacas / totalDocs).toFixed(2),
    };
  }, [data]);

  return (
    <Container>
      <CardProcessosFinalizados
        nameCard="Sacas"
        total={dataAnalytic.totalSacas.toLocaleString("pt-BR")}
        icon={iconBag}
      />
      <CardProcessosFinalizados
        nameCard="Documentos"
        total={dataAnalytic.totalDocs}
        icon={iconDoc}
      />
      <CardProcessosFinalizados
        nameCard="Média"
        total={dataAnalytic.media}
        icon={iconAverage}
      />
    </Container>
  );
}
