import ButtonTable from "../../../../../components/Layouts/ButtonTable/ButtonTable";

export const ProcessosFinalizados = [
  {
    Header: "Previsão",
    accessor: "DAT_PREVISAO",
  },
  {
    Header: "Nome",
    accessor: "NOM_CLIENTE",
  },
  {
    Header: "Documento",
    accessor: "NUM_DOCUMENTO",
    Cell: ({ cell: { value } }) => {
      return <ButtonTable>{value}</ButtonTable>;
    },
  },
  {
    Header: "Status",
    accessor: "NOM_STATUS",
  },
  {
    Header: "Quantidade",
    accessor: "NUM_SACAS",
    Cell: ({ cell: { value } }) => value || 0,
  },
  {
    Header: "Despejo",
    accessor: "NUM_SACAS_DESPEJO",
    Cell: ({ cell: { value } }) => value || 0,
  },
  {
    Header: "D%",
    accessor: "PORCENTAGEM_DESPEJO",
    Cell: ({ cell: { value } }) => `${value.toFixed(2)} %`,
  },
  {
    Header: "Resultado",
    accessor: "NUM_SACAS_RESULTADO",
    Cell: ({ cell: { value } }) => value || 0,
  },
  {
    Header: "R%",
    accessor: "PORCENTAGEM_RESULTADO",
    Cell: ({ cell: { value } }) => `${value.toFixed(2)} %`,
  },
];
