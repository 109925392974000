import moment from "moment/moment";

// data de hoje
export const dayToday = moment();

// data de hoje formatada para string
export const dayTodayFormated = moment().format("DD/MM/YYYY");

// data 7 dias a frente formatada para string
// const dateWeek = moment().add(8, "days").toLocaleDateString();

// data 30 dias a frente formatada para string
export const dateMonth = moment().add(31, "days");
export const dateMonthFormated = moment()
  .subtract(31, "days")
  .format("DD/MM/YYYY");

// data de 7 dias anteriores a hoje
export const lastSevenDay = moment().subtract(7, "days");
export const lastSevenDayFormated = moment()
  .subtract(7, "days")
  .format("DD/MM/YYYY");

// data do primeiro e ultimo dia do mês / ano
export function dateFirstLast(date, selection, format) {
  if (!date) return;
  const firstDay = moment(date).startOf(selection).format(format);
  const lastDay = moment(date).endOf(selection).format(format);
  return { firstDay, lastDay };
}

// primeiro dia do mês formatado
export const firstMonthDayFormated = moment()
  .startOf("month")
  .format("DD/MM/YYYY");

// ultimo dia do mês formatado
export const lastMonthDayFormated = moment()
  .endOf("month")
  .format("DD/MM/YYYY");

// data do primeiro dia do ano
export const dateYear = dayToday.startOf("year");
export const dateYearFormated = dayToday.startOf("year").format("DD/MM/YYYY");

export function dateToEn(date) {
  return date.split("/").reverse().join("-");
}

export const yearCopyright = moment().year();

export function convertSecondsInHours(time) {
  let hours = Math.floor(time / 3600).toFixed(0);
  let minutes = Math.floor((time - hours * 3600) / 60).toFixed(0);
  let seconds = (time - hours * 3600 - minutes * 60).toFixed(0);
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return `${hours}:${minutes}:${seconds}`;
}

export function compararDatas(a, b) {
  const d1 = moment(a, "DD-MM-YYYY");
  const d2 = moment(b, "DD-MM-YYYY");
  if (d1.isAfter(d2)) {
    return 1;
  } else if (d1.isBefore(d2)) {
    return -1;
  }
  return 0;
}

export function formatDateWithHours(date) {
  const newDate = new Date(date);
  const data = new Intl.DateTimeFormat("pt-BR", {
    dateStyle: "short",
    timeStyle: "medium",
  })
    .format(newDate)
    .replace(",", "");

  return data;
}

export function getFirstDayOfYear(dateString) {
  // Converte a string para um objeto moment
  let date = moment(dateString, "DD/MM/YYYY");

  // Obtém o ano da data
  let year = date.year();

  // Cria a data do primeiro dia desse ano
  let firstDay = moment(`01/01/${year}`, "DD/MM/YYYY");

  // Retorna a data formatada
  return firstDay.format("DD/MM/YYYY");
}
