import { useState, useEffect } from "react";
import ChartTotal from "../../../../../../components/Charts/ChartArm/ChartAnalises/ChartTotal";
import { Container } from "./style";

export function Graphic({ height, width, datasetGraphic }) {
  const [widthSize, setWidthSize] = useState(window.innerWidth);

  // Atualizar tamanho da tela
  useEffect(() => {
    const handleResize = () => setWidthSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container>
      <div className="diario">
        <ChartTotal
          datasetGraphic={datasetGraphic}
          height={height}
          width={width}
          displayDataLabel={false}
          responsive={widthSize}
        />
      </div>
    </Container>
  );
}
