import ReactModal from "react-modal";
import iconClosed from "../../../assets/Icons-Modal/icon-closed.svg";
import iconDocument from "../../../assets/Icons-Modal/ICON-DOC.svg";
import iconRealizado from "../../../assets/Icons-Modal/icon-realizado.svg";
import iconProgress from "../../../assets/Icons-Modal/icon-progresso.svg";
import iconSacas from "../../../assets/Icons-Modal/icon-sacas.svg";
import { Container, Header } from "./style";
import CardModal from "./CardModal/CardModal";
import ChartModal from "../../Charts/ChartArm/ChartModal/ChartModal";
import { Link } from "react-router-dom";

// Modal usado no Dashboard / Armazém
// => É o modal usado para renderizar os gráficos e cards de Entrada, Saída, Preparo e Reensaque

export function responsiveModal(valueWidth, valueReturnTrue, valueReturnFalse) {
  if (window.innerWidth < valueWidth) {
    return valueReturnTrue;
  }
  return valueReturnFalse;
}

export default function Modal({
  statusModalActual,
  handleRequestCloseFunc,
  data,
  color,
  title,
}) {
  const isReensaque =
    title.toUpperCase() === "REENSAQUE" ? "À DESPEJAR" : "SACAS";

  return (
    <ReactModal
      isOpen={statusModalActual}
      onRequestClose={handleRequestCloseFunc}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          position: "absolute",
          top: "4%",
          left: responsiveModal(650, "2%", "20%"),
          right: responsiveModal(650, "2%", "20%"),
          bottom: "4%",
          border: "none",
          background: "var(--blue-a1)",
          borderRadius: "8px",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          outline: "none",
          padding: "0",
          maxWidth: "100%",
        },
      }}
      ariaHideApp={false}
    >
      <Header color={color}>
        <h2>{title}</h2>
        <button onClick={handleRequestCloseFunc}>
          <img src={iconClosed} alt="Imagem de fechar" />
        </button>
      </Header>
      <Container>
        <div className="cards">
          <CardModal
            className="card"
            titleCard="DOCUMENTOS"
            icon={iconDocument}
            value={data[1]}
          />
          <CardModal
            className="card"
            titleCard={isReensaque}
            icon={iconSacas}
            value={data[0]}
            onClick={responsiveModal()}
          />
          <CardModal
            className="card"
            titleCard="REALIZADO"
            icon={iconRealizado}
            value={data[2]}
            onClick={responsiveModal()}
          />
          <CardModal
            className="card"
            titleCard="PROGRESSO"
            icon={iconProgress}
            value={data[3]}
            onClick={responsiveModal()}
          >
            <span>%</span>
          </CardModal>
        </div>

        <div className="charts">
          <div className="titleChartModal">
            <span>
              {title.toUpperCase() === "PREPARO"
                ? "Quantidade por status"
                : "Quantidade por embalagem"}
            </span>
          </div>
          <div className="chart">
            <ChartModal embalagens={data[4]} sacasModalidade={data[5]} />
          </div>
        </div>
        <div className="footer">
          <Link to={title.toUpperCase() === "REENSAQUE" || title.toUpperCase() === "INDUSTRIALIZAÇÃO" ? "/armazem/analises/isd" :"/armazem/analises/carga-descarga"}>mais informações</Link>
        </div>
      </Container>
    </ReactModal>
  );
}
