import styled from "styled-components";

export const Container = styled.div`
  button:disabled {
    opacity: 0.5;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 8.75rem;
    width: 250px;
    border-radius: 0.25rem;
    background: var(--blue-a1);
    position: relative;
    float: left;
    border: 1px solid ${({ color }) => color || "var(--green-entry)"};
    width: 240px;
  }

  .card_info {
    display: flex;
    align-content: center;
    justify-content: space-around;
  }

  .card_info span {
    font-size: 2.25rem;
    font-weight: 600;
    color: var(--blue-a13);
  }

  .card_info span p {
    text-align: right;
    font-size: 1rem;
    font-weight: 400;
    color: var(--gray-c3);
  }

  .card_button button {
    border: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--blue-a1);
    font-size: 1rem;
    transition: all 0.3s ease-out;
    background: ${({ color }) => color || "var(--green-entry)"};

    :hover {
      background-color: ${({ color }) =>
        color ? darkenColor(color, -20) : "#00370f"};
    }
  }

  .card_button button img {
    margin-left: 1rem;
  }
`;

// Função para escurecer uma cor (opcional)
const darkenColor = (color, percent) => {
  const num = parseInt(color.replace("#", ""), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = ((num >> 8) & 0x00ff) + amt;
  const B = (num & 0x0000ff) + amt;

  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
    (B < 255 ? (B < 1 ? 0 : B) : 255)
  )
    .toString(16)
    .slice(1)}`;
};
