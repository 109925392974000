import React from "react";
import { Routes, Route } from "react-router-dom";

import PrivateRoutes from "./PrivateRoutes";

import Login from "../pages/login/Login";
import NotFound from "../pages/NotFound/NotFound";
import Home from "../pages/Home/Home";

import ConstructionPage from "../components/Layouts/ConstructionPage/ConstructionPage";
import Contato from "../Modules/Contato/Contato";
import { ArmazemRoutes } from "./armazemRoutes";
import { AppProdutorRoutes } from "./appProdutorRoutes";
import { FinanceiroRoutes } from "./financeiroRoutes";

const Rotas = () => {
  return (
    <>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route element={<Home />} path="/" />
          <Route element={<Contato />} path="/contato" exact />
          {ArmazemRoutes()}
          {AppProdutorRoutes()}
          {FinanceiroRoutes()}
          <Route element={<ConstructionPage />} path="fiscal" />
          <Route element={<ConstructionPage />} path="insumos" />
          <Route element={<ConstructionPage />} path="graos" />
          <Route element={<ConstructionPage />} path="fabrica" />
          <Route element={<ConstructionPage />} path="contabil" />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default Rotas;
