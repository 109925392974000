import { useMemo, useContext } from "react";
import moment from "moment";
import { compararDatas } from "../../../../../../../utils/dates";
import { AnaliseContext } from "../../../../../../../contexts/Analise/AnaliseContext";
import { useRandomColors } from "../../../../hooks/useRandomColors";
import ChartTime from "../../../../../../../components/Charts/ChartArm/ChartAnalises/ChartTime";

export function ChartMonth({ widthSize, data }) {
  const { dscControls } = useContext(AnaliseContext);
  const colors = useRandomColors(dscControls.length);

  const dataSets = useMemo(() => {
    if (!data.length) return { datasets: [], labels: [] };

    const totalPeriodo = {};
    const tempoMedia = {};
    const labelsSet = new Set();
    const groupedData = dscControls.reduce((acc, control) => {
      acc[control] = [];
      return acc;
    }, {});

    data.forEach((item) => {
      const dateLabel = new Date(item.DAT_PESO_INI).toLocaleDateString(
        "default",
        {
          month: "2-digit",
          year: "numeric",
        }
      );

      labelsSet.add(dateLabel);

      if (groupedData[item.DSC_CONTROLE]) {
        groupedData[item.DSC_CONTROLE].push({
          dateLabel,
          numSacas: item.NUM_SACAS || 1,
          timeDiff: moment(item.DAT_PESO_FIM).diff(
            moment(item.DAT_PESO_INI),
            "seconds"
          ),
        });
      }
    });

    const labels = [...labelsSet].sort(compararDatas);

    dscControls.forEach((control) => {
      totalPeriodo[control] = labels.map((label) => {
        return groupedData[control]
          ? groupedData[control].reduce((sum, item) => {
              return item.dateLabel === label ? sum + item.numSacas : sum;
            }, 0)
          : 0;
      });

      tempoMedia[control] = labels.map((label) => {
        const totalSacas =
          totalPeriodo[control].find((_, i) => labels[i] === label) || 1;
        const totalTime = groupedData[control]
          ? groupedData[control].reduce((sum, item) => {
              return item.dateLabel === label ? sum + item.timeDiff : sum;
            }, 0)
          : 0;
        return (totalTime / totalSacas).toFixed(5);
      });
    });

    const datasets = dscControls.map((control, index) => ({
      type: "bar",
      label: control,
      backgroundColor: colors[index],
      data: tempoMedia[control],
    }));

    return { labels, datasets };
  }, [data, dscControls, colors]);

  return (
    <ChartTime
      datasetGraphic={dataSets}
      height="185"
      width="640"
      titleTooltip="Mensal"
      responsive={widthSize}
    />
  );
}
