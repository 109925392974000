import { StyledProgressRoot, StyledProgressIndicator } from "./styles";

export function PercentageBar({ progress, indicatorColor, total }) {
  const percentage = total > 0 ? (progress / total) * 100 : 0;

  return (
    <StyledProgressRoot value={percentage}>
      <StyledProgressIndicator
        indicatorColor={indicatorColor}
        style={{ transform: `translateX(-${100 - percentage}%)` }}
      />
    </StyledProgressRoot>
  );
}
