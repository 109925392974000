import styled from "styled-components";

export const Container = styled.div`
  .charts {
    background-color: var(--blue-a18);
    padding: 0.5rem;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    .chart-day {
      border: 2px solid var(--blue-a16);
      border-radius: 8px;
    }

    .dubble-charts {
      display: grid;
      grid-template-columns: 1fr 1fr;
      place-items: center;
      gap: 0.25rem;

      .chart-month {
        grid-column: 1;
        width: 100%;
        height: 98%;
        border: 2px solid var(--blue-a16);
        border-radius: 8px;
      }
      .chart-year {
        grid-column: 2;
        width: 99%;
        height: 98%;
        border: 2px solid var(--blue-a16);
        border-radius: 8px;
      }
    }
  }
`;
