import { Route } from "react-router-dom";
import VinculaProdutores from "../../Modules/AppProdutor/CadastroProdutor/VinculaProdutores/VinculaProdutores";

export function VinculaProdutoresRoute() {
  return (
    <Route
      element={<VinculaProdutores />}
      path="app-produtor/vincular-produtores"
      exact
    />
  );
}
