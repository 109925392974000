import { HiddenRadio, RadioLabel, StyledRadio } from "./styles";

export function RadioModalidade({
  name,
  value,
  checked,
  onChange,
  modalidade,
}) {
  return (
    <RadioLabel>
      <HiddenRadio
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <StyledRadio />
      {modalidade}
    </RadioLabel>
  );
}
