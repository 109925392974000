import { useState, useContext, useEffect } from "react";

import moment from "moment";

import { AnaliseContext } from "../../../../contexts/Analise/AnaliseContext";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";

import { getDataAnalisePesagem } from "../../../../services/api";

import { convertSecondsInHours } from "../../../../utils/dates";

import { Container } from "./style";

import { HeaderAnalise } from "../components/HeaderAnalise";
import Loading from "../../../../components/Layouts/Loading/Loading";
import { TableCargaDescarga } from "../../../../components/Tables/TablesCargaDescarga/TableCargaDescarga";
import { TempoMedioSacas } from "./components/TempoMedioSacas";
import { TempoMedioTicket } from "./components/TempoMedioTickets";
import { SacasControle } from "./components/SacasControle";
import { PesagemEmbalagem } from "./components/PesagemEmbalagem";
import { SacasTipoCaminhao } from "./components/SacasTipoCaminhão";
import { SacasControleTipoCaminhao } from "./components/SacasControleTipoCaminhao";

export function CargaDescarga() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { date } = useContext(AnaliseContext);
  const { dono, userAuth, password, codEmpresa, codFilial } =
    useContext(AuthContext);

  useEffect(() => {
    async function fetchPesagem() {
      setLoading(true);
      const response = await getDataAnalisePesagem(
        dono,
        userAuth,
        password,
        codEmpresa,
        codFilial,
        "DMAH",
        date.dataAnaliseInicio,
        date.dataAnaliseFinal
      );

      setData(response);
      setLoading(false);
    }
    fetchPesagem();
  }, [date, dono, userAuth, password, codEmpresa, codFilial]);

  const optionsMonthDate = {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const filterDataTable = data.filter((item) => {
    // função para renderizar na tabela o total e o tempo por sacas
    const time = moment(item.DAT_PESO_FIM).diff(
      moment(item.DAT_PESO_INI),
      "seconds"
    );
    const dateInicio = new Date(item.DAT_PESO_INI).toLocaleString(
      `pt-BR`,
      optionsMonthDate
    );
    const dateFim = new Date(item.DAT_PESO_FIM).toLocaleString(
      `pt-BR`,
      optionsMonthDate
    );
    const totalTable = convertSecondsInHours(time);
    const tempoPorSacas = convertSecondsInHours(time / item.NUM_SACAS);

    item.dateInicio = dateInicio;
    item.dateFim = dateFim;
    item.tempoTotal = totalTable;
    item.tempoSacas = tempoPorSacas;

    return item;
  });

  return (
    <Container>
      <HeaderAnalise title="Carga e Descarga" />
      {loading ? (
        <Loading />
      ) : (
        <div className="content">
          <TableCargaDescarga dataTable={filterDataTable} />
          <TempoMedioSacas data={data} />
          <TempoMedioTicket data={data} />
          <SacasControle data={data} />
          <PesagemEmbalagem data={data} />
          <SacasTipoCaminhao data={data} />
          <SacasControleTipoCaminhao data={data} />
        </div>
      )}
    </Container>
  );
}
