import React, { useState, useContext, useEffect } from "react";

import LotesModal from "../../../../components/Modal/LotesModal/LotesModal";
import Warning from "../../../../components/Layouts/Warning/Warning";
import ButtonLoading from "../../../../components/Layouts/Loading/ButtonLoading/ButtonLoading";
import { useFetchAnalise } from "../../../../hooks/useFetchAnalise";
import { Container } from "./style";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";

const RastreioLotes = () => {
  const [value, setValue] = useState("");

  const [statusModal, setStatusModal] = useState(false);
  const [tree, setTree] = useState([]);
  const [loteInfo, setLoteInfo] = useState([]);
  const [viewLoteInfo, setViewLoteInfo] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { dono, userAuth, password, codEmpresa, codFilial } =
    useContext(AuthContext);

  const { getDataLoteTree } = useFetchAnalise(
    dono,
    userAuth,
    password,
    codEmpresa,
    codFilial
  );

  const handleRequestCloseFunc = () => {
    setStatusModal(false);
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setViewLoteInfo(false);
    setError(false);
    setLoading(true);

    const result = await getDataLoteTree(value.toUpperCase());
    setTree(result);
    setLoading(false);

    if (result.length === 0) {
      setError(true);
    } else {
      setError(false);
    }

    setValue("");
    setViewLoteInfo(true);
  }

  function handleChange(event) {
    setValue(event.target.value);
    setError(false);
  }

  useEffect(() => {
    if (tree && tree.length > 0) {
      const lote = tree.filter((lotes) => lotes.NUM_INDICE === 0);
      setLoteInfo(lote);
    }

    return () => setLoteInfo([]);
  }, [tree]);

  return (
    <>
      <Container className="div-lote">
        <LotesModal
          statusModalActual={statusModal}
          onRequestClose={handleRequestCloseFunc}
          tree={tree}
          setViewLoteInfo={setViewLoteInfo}
          lote={loteInfo.length > 0 ? loteInfo[0].COD_LOTE : ""}
        />
        <h2>Rastreio de Lote:</h2>
        <form onSubmit={handleSubmit} className="formLotes">
          {/* <label htmlFor="lote">Lote</label> */}
          <input
            type="text"
            id="lote"
            name="lote"
            onChange={handleChange}
            value={value}
            required
            autoFocus
            placeholder="Digite o número do lote"
          />
          <button disabled={!value || loading}>
            {loading ? <ButtonLoading /> : "Pesquisar"}
          </button>
        </form>

        {viewLoteInfo && loteInfo.length > 0 && (
          <div className="detailsLotes">
            <p>Lote: {loteInfo[0].COD_LOTE}</p>
            <p>Código do Cliente: {loteInfo[0].COD_CADASTRO}</p>
            <p>Cliente: {loteInfo[0].NOM_CADASTRO}</p>
            <p>Nota Fiscal: {loteInfo[0].NUM_NOTA}</p>
            <p>Tipo do Documento: {loteInfo[0].NOM_SERIE}</p>
            <button
              onClick={() => setStatusModal(true)}
              className="buttonLotes"
            >
              Detalhar
            </button>
          </div>
        )}
        {error && (
          <Warning
            className="ErrorLote"
            label="Lote inexistente ou sem histórico"
          />
        )}
      </Container>
    </>
  );
};

export default RastreioLotes;
