import { generateId } from "../../../../utils/functions";
import { Container, HeaderSection, Table, StatusCircle } from "../style";
import { NoData } from "../components/NoData/NoData";
import { Title } from "../components/Title/Title";
import { PercentageGraphic } from "../components/PercentageGraphic/PercentageGraphic";
import { formatNumber } from "../../../../utils/regex";

export function SaidasPainel({ refProp, data, day, date }) {
  const dataSaida = data
    .filter((item) => item.FLG_MOVIMENTO === "S" || item.FLG_MOVIMENTO === "L")
    .map((item) => ({
      ...item,
      id: generateId(),
    }));

  const totalSacas = dataSaida.reduce((acc, item) => acc + item.NUM_SACAS, 0);

  if (data.length === 0) {
    return <NoData tipo="saida" day={day} date={date} refTipo={refProp} />;
  }

  return (
    <Container ref={refProp}>
      <HeaderSection>
        <Title tipo="Saida" day={day} date={date} totalSacas={totalSacas} />
      </HeaderSection>
      <Table>
        <thead>
          <tr>
            <th />
            <th>Previsão</th>
            <th>Efetivo</th>
            <th>Motorista</th>
            <th>Nome</th>
            <th>Quantidade</th>
            <th>Modalidade</th>
            <th>DOC</th>
            <th>CTL</th>
            <th>Status</th>
            <th>QTD Tempo real</th>
            <th>Sacas Armazém</th>
          </tr>
        </thead>
        <tbody>
          {dataSaida.map((item) => {
            return (
              <tr key={item.id}>
                <td>
                  <StatusCircle $bgColor={item.NOM_COR_STATUS} />
                </td>
                <td>{item.DAT_PREVISAO}</td>
                <td>{item.DAT_EFETIVA}</td>
                <td>{item.NOM_MOTORISTA}</td>
                <td>{item.NOM_CLIENTE}</td>
                <td>{formatNumber(item.NUM_SACAS)}</td>
                <td>{item.NOM_MODALIDADE}</td>
                <td>{item.NUM_DOCUMENTO}</td>
                <td>{item.NUM_CONTROLE_EMPRESA}</td>
                <td
                  style={{
                    color: `${item.NOM_COR_STATUS}`,
                    fontWeight: "bold",
                  }}
                >
                  {item.NOM_STATUS}
                </td>
                <td>
                  <PercentageGraphic
                    percentage={item.NUM_PERC_DESCARGA}
                    numSacas={item.NUM_SACAS}
                  />
                </td>
                <td>{item.NOM_ARMAZEM}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
}
