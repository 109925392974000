import { useMemo } from "react";
import ChartTotal from "../../../../../../../components/Charts/ChartArm/ChartAnalises/ChartTotal";

export function ChartMonth({ widthSize, data }) {
  const dataSets = useMemo(() => {
    if (!data.length) return { datasets: [], labels: [] };

    const total = {
      bigbag: [],
      sacaria: [],
      granel: [],
      outras: [],
    };
    const labels = [];

    let bigbag = 0;
    let sacaria = 0;
    let granel = 0;
    let outras = 0;

    data.forEach((item, index, array) => {
      const dateItem = new Date(item.DAT_PESO_INI).toLocaleDateString(
        "default",
        {
          month: "2-digit",
          year: "numeric",
        }
      );

      const nextDate =
        index < array.length - 1
          ? new Date(array[index + 1].DAT_PESO_INI).toLocaleDateString(
              "default",
              {
                month: "2-digit",
                year: "numeric",
              }
            )
          : null;

      if (item.NUM_BIGBAG === 1) bigbag += 1;
      if (item.NUM_SACARIA === 1) sacaria += 1;
      if (item.NUM_GRANEL === 1) granel += 1;
      if (item.NUM_OUTRAS === 1) outras += 1;

      if (dateItem !== nextDate || index === array.length - 1) {
        labels.push(dateItem);
        total.bigbag.push(bigbag);
        total.sacaria.push(sacaria);
        total.granel.push(granel);
        total.outras.push(outras);

        // Reset para a próxima contagem
        bigbag = 0;
        sacaria = 0;
        granel = 0;
        outras = 0;
      }
    });

    return {
      labels,
      datasets: [
        {
          type: "bar",
          label: "Granel",
          backgroundColor: "#1ecfd6",
          data: total.granel,
        },
        {
          type: "bar",
          label: "Bigbag",
          backgroundColor: "#003d73",
          data: total.bigbag,
        },
        {
          type: "bar",
          label: "Sacaria",
          backgroundColor: "#c05640",
          data: total.sacaria,
        },
        {
          type: "bar",
          label: "Outras",
          hidden: true,
          backgroundColor: "#edd170",
          data: total.outras,
        },
      ],
    };
  }, [data]); // ✅ `useMemo` para otimizar desempenho

  return (
    <ChartTotal
      datasetGraphic={dataSets}
      height="185"
      width="640"
      titleTooltip="Mensal"
      displayDataLabel={true}
      responsive={widthSize}
    />
  );
}
