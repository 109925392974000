import { DashboardFinRoute } from "./DashboardFinRoute";
import { RelatoriosFinRoute } from "./RelatoriosFinRoute";

export function FinanceiroRoutes() {
  return (
    <>
      {DashboardFinRoute()}
      {RelatoriosFinRoute()}
    </>
  );
}
