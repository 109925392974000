import { useContext, useEffect, useState, useMemo } from "react";
import { compararDatas } from "../../../../../../utils/dates";
import { AnaliseContext } from "../../../../../../contexts/Analise/AnaliseContext";
import { useRandomColors } from "../../../hooks/useRandomColors";
import { Graphic } from "../Graphic";

export function PesoPorDia({ data }) {
  const [dataSets, setDataSets] = useState({ datasets: [], labels: [] });
  const { dscControls } = useContext(AnaliseContext);

  const colors = useRandomColors(dscControls.length);

  const generateLabel = useMemo(() => {
    if (!data.length) return [];

    const optionDate = { day: "2-digit", month: "2-digit", year: "numeric" };
    const labelsSet = new Set();

    dscControls.forEach((controls) => {
      data.forEach((item) => {
        if (item.DSC_CONTROLE === controls) {
          labelsSet.add(
            new Date(item.DAT_PESO_INI).toLocaleString("default", optionDate)
          );
        }
      });
    });

    return Array.from(labelsSet).sort(compararDatas);
  }, [data, dscControls]);

  const calculatedData = useMemo(() => {
    if (!data.length || !generateLabel.length)
      return { totalGeral: {}, totalSacas: {}, qtdPesagens: {} };

    const optionDate = { day: "2-digit", month: "2-digit", year: "numeric" };
    let totalGeral = {},
      totalSacas = {},
      qtdPesagens = {};

    dscControls.forEach((controls) => {
      totalGeral[controls] = [];
      totalSacas[controls] = [];
      qtdPesagens[controls] = [];

      generateLabel.forEach((label) => {
        let totalPeso = 0,
          totalSaca = 0,
          totalPesagem = 0;

        data.forEach((item) => {
          if (item.DSC_CONTROLE === controls) {
            let dateItem = new Date(item.DAT_PESO_INI).toLocaleString(
              "default",
              optionDate
            );
            if (label === dateItem) {
              totalPeso += item.NUM_PESO_LIQUIDO;
              totalSaca += item.NUM_SACAS;
              totalPesagem += item.NUM_PESAGENS;
            }
          }
        });

        totalGeral[controls].push(totalPeso);
        totalSacas[controls].push(totalSaca);
        qtdPesagens[controls].push(totalPesagem);
      });
    });

    return { totalGeral, totalSacas, qtdPesagens };
  }, [data, dscControls, generateLabel]);

  const datasets = useMemo(() => {
    return dscControls.map((controls, index) => ({
      type: "bar",
      label: controls,
      backgroundColor:
        colors[index] ||
        `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${
          Math.random() * 255
        })`,
      data: calculatedData.totalGeral[controls] || [],
      totalSacas: calculatedData.totalSacas[controls] || [],
      qtdPesagens: calculatedData.qtdPesagens[controls] || [],
    }));
  }, [calculatedData, dscControls, colors]);

  useEffect(() => {
    setDataSets({ labels: generateLabel, datasets });
  }, [generateLabel, datasets]);

  return (
    <div>
      <h3>Peso por dia</h3>
      <Graphic height="370" width="1200" datasetGraphic={dataSets} />
    </div>
  );
}
