import React from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, ChartDataLabels);

export const DespResGraphic = ({ data, total }) => {
  const chartData = {
    labels: ["Despejo", "Resultado"],
    datasets: [
      {
        label: "Sacas",
        data: data,
        backgroundColor: ["#00751F", "#009FE3"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: "y", // Horizontal bars
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        max: total, // Ajusta o máximo no eixo X ao total
      },
    },
    plugins: {
      datalabels: {
        color: "#FFFFFF", // Cor do texto dentro das barras
        font: {
          size: 14,
          weight: "semibold",
        },
        align: "center", // Alinha o texto no centro
        anchor: "center", // Mantém o texto dentro da barra
      },
      title: {
        display: true,
        text: `Total de Sacas: ${total}`, // Mostra o total como título
      },
      legend: {
        display: false, // Esconde a legenda
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw; // Valor da barra
            const percentage = ((value / total) * 100).toFixed(2); // Calcula porcentagem
            return `${value} sacas (${percentage}%)`; // Exibe valor e porcentagem
          },
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};
