import { AnaliseRoute } from "./AnaliseRoute";
import { DashboardRoute } from "./DashboardRoute";
import { MapaRoute } from "./MapaRoute";
import { PainelTempoRealRoute } from "./PainelTempoRealRoute";
import { RelatoriosArmRoute } from "./RelatoriosArmRoute";
import { TagsRoute } from "./TagsRoutes";

export function ArmazemRoutes() {
  return (
    <>
      {DashboardRoute()}
      {AnaliseRoute()}
      {RelatoriosArmRoute()}
      {PainelTempoRealRoute()}
      {TagsRoute()}
      {MapaRoute()}
    </>
  );
}
