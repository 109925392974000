import { useState, useContext } from "react";

import moment from "moment";

import { useQuery } from "@tanstack/react-query";

import { getDataPainelRealTime } from "../../../services/api";

import { AuthContext } from "../../../contexts/Auth/AuthContext";

export const initialState = {
  entrada: {
    day: moment().format("YYYY-MM-DD"),
    flag: "A",
  },
  saida: {
    day: moment().format("YYYY-MM-DD"),
    flag: "A",
  },
  preparo: {
    day: moment().format("YYYY-MM-DD"),
    flag: "A",
  },
  diversos: {
    day: moment().format("YYYY-MM-DD"),
    flag: "A",
  },
};

export function usePainelTempoReal() {
  const [date, setDate] = useState(initialState);

  const { dono, userAuth, password, codEmpresa, codFilial } =
    useContext(AuthContext);

  const dayEntrada = moment(date.entrada.day, "YYYY-MM-DD").format(
    "DD/MM/YYYY"
  );
  const daySaida = moment(date.saida.day, "YYYY-MM-DD").format("DD/MM/YYYY");
  const dayPreparo = moment(date.preparo.day, "YYYY-MM-DD").format(
    "DD/MM/YYYY"
  );
  const dayDiversos = moment(date.diversos.day, "YYYY-MM-DD").format(
    "DD/MM/YYYY"
  );

  const { data, isLoading } = useQuery({
    queryKey: ["painel", date],
    queryFn: async () => {
      const data = await getDataPainelRealTime(
        dono,
        userAuth,
        password,
        codEmpresa,
        codFilial,
        dayEntrada,
        daySaida,
        dayPreparo,
        dayDiversos,
        date.entrada.flag,
        date.saida.flag,
        date.preparo.flag,
        date.diversos.flag
      );
      return data;
    },
    refetchInterval: 60000,
  });

  return {
    isLoading,
    data,
    date,
    setDate,
    daySaida,
    dayEntrada,
    dayPreparo,
    dayDiversos,
  };
}
