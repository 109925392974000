import { NavLink } from "react-router-dom";
import dashboard from "../../../assets/Icons Sidebar/icon-dashboard.svg";
import relatorios from "../../../assets/Icons Sidebar/icon-relatorios.svg";
import { ItemMenu } from "../style";

// Opções do Menu usados no Sidebar do Financeiro

export default function MenuFinanceiro({ setShowMenu, selected }) {
  return (
    <>
      <ItemMenu>
        <NavLink
          to="/financeiro"
          end
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={dashboard} alt="" className="menu_img" />
          Dashboard
        </NavLink>
      </ItemMenu>

      {/* <div>
        <NavLink
          to="financeiro/analises"
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={analises} alt="" className="menu_img" />
          Análises
        </NavLink>
      </div> */}

      <ItemMenu>
        <NavLink
          to="/financeiro/relatorios"
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={relatorios} alt="" className="menu_img" />
          Relatórios
        </NavLink>
      </ItemMenu>
    </>
  );
}
