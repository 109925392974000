import { Container } from "./styles";

export function PercentageSacas({ percentage, numSacas }) {
  const percentageSacas = `${(
    (Number(percentage || 0) / Number(numSacas || 0)) *
    100
  ).toFixed(1)}%`;

  return (
    <Container>
      <div>{percentage || "0"}</div>
      <div>{percentageSacas}</div>
    </Container>
  );
}
