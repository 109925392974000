import { ChevronLeft } from "lucide-react";
import styled from "styled-components";

export const Footer = styled.div`
  background-color: var(--blue-a16);

  footer {
    font-size: 0.62rem;
    color: var(--gray-c3);
    display: flex;
    justify-content: space-around;
    margin-bottom: 0.5rem;
  }

  .nav {
    padding: 0.5rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export const Container = styled.div`
  background: var(--blue-a18);
  grid-area: sidebar;
  display: grid;
  overflow: auto;
  transition: all 0.5s;
  --webkit-transition: all 0.5s;

  .button_close {
    padding: 1rem;
    display: flex;
    justify-content: end;
    img {
      display: none;
    }
  }
  .icon-showMenu {
    display: none;
  }

  .portalAntigo {
    border: none;
    appearance: none;
    background: none;
    outline: none;
    color: white;
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin-left: 1rem;
    margin-bottom: 10px;
  }

  .subMenu {
    display: flex;
    flex-direction: column;

    .menu_link_antigo {
      display: flex;
      width: 100%;
      margin-bottom: 10px;
      font-size: 0.875rem;
      padding-left: 3.5rem;
      color: var(--blue-a1);
    }
  }

  .sidebar_logo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1.5rem;

    .img_logo {
      width: 100%;
      height: 5rem;
    }

    .img_separador {
      width: 100%;
      margin-top: 1.5rem;
      padding: 0rem 1rem;
    }
  }

  .menu_link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    width: 100%;
    padding: 0.3rem 0;
  }

  .menu_img {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
    margin-left: 1rem;
    overflow: scroll;
  }

  .wrapper_analise {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .links {
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      gap: 0.5rem;

      a {
        padding: 0.2rem 0.5rem;
        border-radius: 0.25rem;
        font-size: 14px;

        :hover {
          background-color: var(--blue-a15);
        }
      }
    }

    div:first-child {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0.3rem 0;
    }
  }

  /*estilização para responsividade*/
  .sidebar-responsive {
    display: inline;
    z-index: 9999;
    left: 0;
    position: absolute;
  }

  @media only screen and (max-width: 2560px) {
    background: ${(props) => (props.className ? "" : "transparent")};
    margin: 15px 0 0 10px;
    .icon-showMenu {
      height: 25px;
      display: flex;
      img {
        cursor: pointer;
      }
    }

    .menuActive {
      display: block;
    }
    .menuDisabled {
      display: none;
    }
    z-index: 9999;
    left: 0;
    position: absolute;
    .button_close img {
      cursor: pointer;
      display: block;
    }
  }
`;

export const IconAnalise = styled(ChevronLeft)`
  position: relative;
  left: 34%;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? "rotate(-90deg)" : "rotate(0deg)")};
`;

export const ItemMenu = styled.div`
  color: var(--blue-a1);
  padding: 0rem 1rem;
  font-size: 1rem;
  margin-bottom: 1rem;
`;
