import FormSearchProdutor from "./CadastroProdutor/FormSearchProdutor/FormSearchProdutor";
import { Container } from "./style";
import FormVinculaProdutores from "./CadastroProdutor/VinculaProdutores/FormVinculaProdutores/FormVinculaProdutores";

const HomeAppProdutor = () => {
  return (
    <Container>
      <FormVinculaProdutores />
      <FormSearchProdutor />
      {/* <ListarProdutores /> */}
    </Container>
  );
};

export default HomeAppProdutor;
