import styled from "styled-components";

export const Container = styled.div`
  grid-area: main;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--blue-a3);
`;

export const HeaderSection = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.$bgColor || " #e6a122"};

  h2 {
    font-weight: 400;
  }
`;

export const Table = styled.table`
  width: 100%;
  font-size: 12px;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #595959;
    text-align: center;
    padding: 2px;
  }

  th {
    background-color: var(--gray-c2);
  }

  td {
    text-transform: uppercase;
  }

  tr:nth-child(odd) {
    background-color: #fff;
  }
`;

export const StatusCircle = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 10px;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${(props) => props.$bgColor || "#000"};
  }
`;

// Container para o botão fixo
export const FixedButtonContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

// Estilo do botão
export const ScrollButton = styled.button`
  background-color: #007bff;
  opacity: 0.6;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease, transform 0.2s ease;

  &:hover {
    opacity: 1;
  }
`;

// Estilo do conteúdo do HoverCard
export const HoverContent = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  padding: 10px;
  font-size: 14px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: #f0f0f0;
    }
  }
`;

export const ContainerBarGraphic = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
