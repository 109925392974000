import { Route } from "react-router-dom";

import PainelTempoReal from "../../Modules/Armazem/PainelTempoReal/PainelTempoReal";

export function PainelTempoRealRoute() {
  return (
    <Route
      element={<PainelTempoReal />}
      path="armazem/painel-tempo-real"
      exact
    />
  );
}
