import React from "react";
import { NavLink } from "react-router-dom";
import usersIcon from "../../../assets/Icons Sidebar/icon-users.svg";
import relatorios from "../../../assets/Icons Sidebar/icon-relatorios.svg";
import contato from "../../../assets/Icons Sidebar/icon-contato.svg";
import customIcon from "../../../assets/Icons Sidebar/icon-custom.svg";
import notification from "../../../assets/Icons Sidebar/icon-notification.svg";
import menu from "../../../assets/Icons Sidebar/icon-dashboard.svg";
import { ItemMenu } from "../style";

const MenuAppProdutor = ({ setShowMenu, selected }) => {
  return (
    <>
      <ItemMenu>
        <NavLink
          to="/app-produtor"
          end
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={usersIcon} alt="" className="menu_img" />
          Usuários
        </NavLink>
      </ItemMenu>

      <ItemMenu>
        <NavLink
          to="/app-produtor/quadro-avisos"
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={relatorios} alt="" className="menu_img" />
          Quadro de avisos
        </NavLink>
      </ItemMenu>

      <ItemMenu>
        <NavLink
          to="/app-produtor/vincular-produtores"
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={contato} alt="" className="menu_img" />
          Vincular Produtores
        </NavLink>
      </ItemMenu>

      <ItemMenu>
        <NavLink
          to="/app-produtor/notificacoes"
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={notification} alt="" className="menu_img" />
          Notificações
        </NavLink>
      </ItemMenu>

      <ItemMenu>
        <NavLink
          to="/app-produtor/modulos-menu"
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={menu} alt="" className="menu_img" />
          Módulos do Menu
        </NavLink>
      </ItemMenu>

      {/* <div>
        <NavLink
          to="/app-produtor/custom-app"
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={customIcon} alt="" className="menu_img" />
          Customização
        </NavLink>
      </div> */}
    </>
  );
};

export default MenuAppProdutor;
