import { useMemo, useContext } from "react";
import { AnaliseContext } from "../../../../../../../contexts/Analise/AnaliseContext";
import { useRandomColors } from "../../../../hooks/useRandomColors";
import { compararDatas } from "../../../../../../../utils/dates";
import ChartTotal from "../../../../../../../components/Charts/ChartArm/ChartAnalises/ChartTotal";

export function ChartDay({ widthSize, data }) {
  const { dscControls } = useContext(AnaliseContext);
  const colors = useRandomColors(dscControls.length);

  const dataSets = useMemo(() => {
    if (!data.length) return { datasets: [], labels: [] };

    const totalPeriodo = {};
    const labelsSet = new Set();

    const groupedData = dscControls.reduce((acc, control) => {
      acc[control] = [];
      return acc;
    }, {});

    data.forEach((item) => {
      const dateLabel = new Date(item.DAT_PESO_INI).toLocaleDateString(
        "default",
        {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }
      );

      labelsSet.add(dateLabel);

      if (groupedData[item.DSC_CONTROLE]) {
        groupedData[item.DSC_CONTROLE].push({
          dateLabel,
          numSacas: item.NUM_SACAS,
        });
      }
    });

    const labels = [...labelsSet].sort(compararDatas);

    dscControls.forEach((control) => {
      totalPeriodo[control] = labels.map((label) => {
        return groupedData[control]
          ? groupedData[control].reduce((sum, item) => {
              return item.dateLabel === label ? sum + item.numSacas : sum;
            }, 0)
          : 0;
      });
    });

    const datasets = dscControls.map((control, index) => ({
      type: "bar",
      label: control,
      backgroundColor: colors[index],
      data: totalPeriodo[control],
    }));

    return { labels, datasets };
  }, [data, dscControls, colors]);

  return (
    <ChartTotal
      datasetGraphic={dataSets}
      height="220"
      width="1280"
      titleTooltip="Diário"
      displayDataLabel={false}
      responsive={widthSize}
    />
  );
}
