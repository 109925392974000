import { useState, useContext, useEffect, useMemo } from "react";
import { AnaliseContext } from "../../../../../../contexts/Analise/AnaliseContext";
import { useRandomColors } from "../../../hooks/useRandomColors";
import { Graphic } from "../Graphic";

export function PesagemPorCaminhao({ data }) {
  const [dataSets, setDataSets] = useState({ datasets: [], labels: [] });
  const { dscControls } = useContext(AnaliseContext);

  const colors = useRandomColors(dscControls.length);

  // Processamento de dados otimizado
  const processedData = useMemo(() => {
    if (!data.length) return { labels: [], datasets: [] };

    const labels = ["Caçamba", "Graneleiro", "Outros"];
    const totalGeral = dscControls.reduce((acc, control) => {
      acc[control] = [0, 0, 0];
      return acc;
    }, {});

    data.forEach((item) => {
      if (totalGeral[item.DSC_CONTROLE]) {
        if (item.NUM_CACAMBA_CAM === 1) totalGeral[item.DSC_CONTROLE][0] += 1;
        if (item.NUM_GRANELEIRO_CAM === 1)
          totalGeral[item.DSC_CONTROLE][1] += 1;
        if (item.NUM_OUTROS_CAM === 1) totalGeral[item.DSC_CONTROLE][2] += 1;
      }
    });

    const datasets = dscControls.map((control, index) => ({
      type: "bar",
      label: control,
      backgroundColor: colors[index],
      data: totalGeral[control],
    }));

    return { labels, datasets };
  }, [data, dscControls, colors]);

  useEffect(() => {
    setDataSets(processedData);
  }, [processedData]);

  return (
    <div>
      <h3>Quantidade de pesagem por Caminhão</h3>
      <Graphic height="370" width="580" datasetGraphic={dataSets} />
    </div>
  );
}
