import { useState } from "react";
import { Container } from "./style";
import more from "../../../assets/Icons Box/icon-mais.svg";
import Loading from "../../Layouts/Loading/Loading";
import Modal from "../../Modal/ModalArm/Modal";

// Componente que cria os cards do Dashboard / Módulo Armazém

export default function CardArm(props) {
  const [statusModal, setStatusModal] = useState(false);

  const handleShowModal = () => {
    setStatusModal(true);
  };

  const handleRequestCloseFunc = () => {
    setStatusModal(false);
  };

  return (
    <>
      <Container color={props.color}>
        <Modal
          statusModalActual={statusModal}
          handleRequestCloseFunc={handleRequestCloseFunc}
          data={props.data}
          color={props.color}
          title={props.title}
        />
        <div className={`card ${props.card}`}>
          {props.loading && <Loading />}
          {!props.loading && (
            <div className="card_info">
              <img src={props.icon} alt="" />
              <span>
                {props.data[0]}
                <p>sacas</p>
              </span>
            </div>
          )}
          <div className="card_button">
            <button
              disabled={props.loading}
              className={props.card_button}
              onClick={handleShowModal}
            >
              {props.title}
              <img src={more} alt="" />
            </button>
          </div>
        </div>
      </Container>
    </>
  );
}
