import { useContext } from "react";

import { Navigate, Outlet } from "react-router-dom";

import Loading from "../components/Layouts/Loading/Loading";
import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar/Sidebar";

import { AuthContext } from "../contexts/Auth/AuthContext";

import { Container } from "../utils/style";
import { AmbienteContext } from "../contexts/Home/AmbienteContext";

const PrivateRoutes = () => {
  const { userAuth } = useContext(AuthContext);
  const { ambiente } = useContext(AmbienteContext);
  const storageSession = sessionStorage.getItem("newBiWeb");

  if (!userAuth && storageSession) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return userAuth ? (
    <>
      {ambiente && <Sidebar />}
      <Navbar />
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
