import { Route } from "react-router-dom";
import ConstructionPage from "../../components/Layouts/ConstructionPage/ConstructionPage";

export function CustomAppRoute() {
  return (
    <Route
      element={<ConstructionPage />}
      path="app-produtor/custom-app"
      exact
    />
  );
}
