import { useState } from "react";
import ButtonCalendar from "../../../../../components/Layouts/ButtonCalendar/ButtonCalendar";
import { DateModalRealTime } from "../DateModalRealTime/DateModalRealTime";
import * as S from "./styles";

export function Header({ setDate, date, handleInitialState }) {
  const [statusModalCalendar, setStatusModalCalendar] = useState(false);

  const handleShowModalCalendar = () => {
    setStatusModalCalendar(true);
  };

  const handleRequestCloseCalendar = () => {
    setStatusModalCalendar(false);
  };

  return (
    <S.Header>
      <DateModalRealTime
        setDate={setDate}
        date={date}
        handleRequestCloseCalendar={handleRequestCloseCalendar}
        statusModalCalendar={statusModalCalendar}
      />
      <h2>Processos Diários</h2>
      <ButtonCalendar handleShowModalCalendar={handleShowModalCalendar} />
    </S.Header>
  );
}
