import moment from "moment";
import { lastSevenDayFormated, dayTodayFormated } from "../utils/dates";
import { apiDecryptURL } from "../utils/webService";

function formatDateBrEn(dataOriginal) {
  // Testando se a data está no formato brasileiro (dd/mm/aaaa)
  if (!dataOriginal) return null;
  return moment(dataOriginal).format("DD/MM/YYYY");
}

export const validateUser = async (user, password) => {
  const api = apiDecryptURL();
  const url = "/WebService.asmx/ValidaPainelUsuarioJSon";
  const dadosUsuario = `Usuario=${user}&Senha=${password}`;
  const { data } = await api.post(url, dadosUsuario);
  const dataLogin = await data;
  return dataLogin;
};

export const tableApi = async (
  dono,
  user,
  password,
  firstEntrada,
  lastEntrada,
  firstSaida,
  lastSaida,
  firstPreparo,
  lastPreparo,
  codEmpresa,
  codFilial
) => {
  firstEntrada = !firstEntrada ? "01/01/0001" : formatDateBrEn(firstEntrada);
  lastEntrada = !lastEntrada ? dayTodayFormated : formatDateBrEn(lastEntrada);
  firstSaida = !firstSaida ? "01/01/0001" : formatDateBrEn(firstSaida);
  lastSaida = !lastSaida ? dayTodayFormated : formatDateBrEn(lastSaida);
  firstPreparo = !firstPreparo ? "01/01/0001" : formatDateBrEn(firstPreparo);
  lastPreparo = !lastPreparo ? dayTodayFormated : formatDateBrEn(lastPreparo);

  const api = apiDecryptURL();
  const urlTable = "/WebService.asmx/RetProcessosDiariosDatasJSon";

  const authTable = `Dono=${dono}&Usuario=${user}&Senha=${password}&dat_entrada_ini=${firstEntrada}&dat_entrada_fim=${lastEntrada}&dat_saida_ini=${firstSaida}&dat_saida_fim=${lastSaida}&dat_preparo_ini=${firstPreparo}&dat_preparo_fim=${lastPreparo}&Empresa=${codEmpresa}&Filial=${codFilial}`;

  const { data } = await api.post(urlTable, authTable);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

// Função para chamar na rota /RetPesagemPeriodoJSon do webService
export const getDataAnalisePesagem = async (
  dono,
  user,
  password,
  empresa,
  filial,
  agrupamento,
  firstEntrada,
  lastEntrada = dayTodayFormated
) => {
  const api = apiDecryptURL();
  const url = "/WebService.asmx/RetPesagemPeriodoJSon";
  const authAnalise = `Dono=${dono}&Usuario=${user}&Senha=${password}&Empresa=${empresa}&Filial=${filial}&Agrupamento=${agrupamento}&DataInicial=${firstEntrada}&DataFinal=${lastEntrada}`;

  const { data } = await api.post(url, authAnalise);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

// Função para chamar a API /RetProcessosFechadosVMJSon => Armazém/Análises/Processos Finalizados
export const getDataAnaliseProcessosFin = async (
  dono,
  user,
  password,
  empresa,
  filial,
  firstEntrada = lastSevenDayFormated,
  tipoMovimento,
  lastEntrada = dayTodayFormated
) => {
  const api = apiDecryptURL();
  const url = "/WebService.asmx/RetProcessosFechadosVMJSon";
  const authAnalise = `Dono=${dono}&Usuario=${user}&Senha=${password}&Empresa=${empresa}&Filial=${filial}&DataInicial=${firstEntrada}&DataFinal=${lastEntrada}&TipoMovimento=${tipoMovimento}`;

  const { data } = await api.post(url, authAnalise);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

export const getDataResISPainel = async (
  dono,
  user,
  password,
  empresa,
  filial,
  is
) => {
  const api = apiDecryptURL();
  const url = "/WebService.asmx/RetISPainelJSon";
  const authAnalise = `Dono=${dono}&Usuario=${user}&Senha=${password}&Empresa=${empresa}&Filial=${filial}&IS=${is}`;

  const { data } = await api.post(url, authAnalise);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

export const getDataResIS = async (dono, user, password, empresa, filial) => {
  const api = apiDecryptURL();
  const urlTable = "/WebService.asmx/RetProcessosDiariosDatasJSon";
  const authTable = `Dono=${dono}&Usuario=${user}&Senha=${password}&dat_entrada_ini=${""}&dat_entrada_fim=${""}&dat_saida_ini=${""}&dat_saida_fim=${""}&dat_preparo_ini=${"01/01/0001"}&dat_preparo_fim=${dayTodayFormated}&Empresa=${empresa}&Filial=${filial}`;
  const { data } = await api.post(urlTable, authTable);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

// Flag da data => A=Anterior a data, P=Posterior e D=Data do dia
export const getDataPainelRealTime = async (
  dono,
  user,
  password,
  empresa,
  filial,
  dataServEntrada = dayTodayFormated,
  dataServSaida = dayTodayFormated,
  dataServPreparo = dayTodayFormated,
  dataServDiversos = dayTodayFormated,
  flagServEntrada = "A",
  flagServSaida = "A",
  flagServPreparo = "A",
  flagServDiversos = "A"
) => {
  const api = apiDecryptURL();
  const urlTable = "/WebService.asmx/RetProcessosDiariosDatas2JSon";
  const authTable = `Dono=${dono}&Usuario=${user}&Senha=${password}&dat_entrada=${dataServEntrada}&dat_saida=${dataServSaida}&dat_preparo=${dataServPreparo}&dat_outro=${dataServDiversos}&flg_entrada_ap=${flagServEntrada}&flg_saida_ap=${flagServSaida}&flg_preparo_ap=${flagServPreparo}&flg_outro_ap=${flagServDiversos}&Empresa=${empresa}&Filial=${filial}`;
  const { data } = await api.post(urlTable, authTable);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

export const getDataTree = async (
  dono,
  user,
  password,
  empresa,
  filial,
  lote,
  typeMoviment = "X"
) => {
  const api = apiDecryptURL();
  const url = "/WebService.asmx/View_Ret_Arvore_LoteJSon";
  const auth = `Dono=${dono}&Usuario=${user}&Senha=${password}&Empresa=${empresa}&Filial=${filial}&Lote=${lote}&AscDesc=${typeMoviment}`;
  const { data } = await api.post(url, auth);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

export const getDataHistoricoTag = async (
  dono,
  user,
  password,
  empresa,
  filial,
  tag,
  lote = "",
  dataIni = "",
  dataFim = ""
) => {
  const api = apiDecryptURL();
  const url = "/WebService.asmx/View_Ret_Historico_TAGJSon";
  const auth = `Dono=${dono}&Usuario=${user}&Senha=${password}&Empresa=${empresa}&Filial=${filial}&Tag=${tag}&Lote=${lote}&DataIni=${dataIni}&DataFim=${dataFim}`;
  const { data } = await api.post(url, auth);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

export const getDataSqlJson = async (user, password, codSql) => {
  const api = apiDecryptURL();
  const url = "/WebService.asmx/GetSqlJSon";
  const auth = `Usuario=${user}&Senha=${password}&ComandoSQL=${codSql}`;
  const { data } = await api.post(url, auth);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};
