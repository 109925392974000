import {
  ScrollButton,
  HoverContent,
  FixedButtonContainer,
  Container,
} from "./style";

import { SaidasPainel } from "./SaidasPainel/SaidasPainel";
import { PreparoPainel } from "./PreparoPainel/PreparoPainel";
import { LigaPainel } from "./LigaPainel/LigaPainel";
import { EntradasPainel } from "./EntradasPainel/EntradasPainel";
import { DiversosPainel } from "./DiversosPainel/DiversosPainel";
import Loading from "../../../components/Layouts/Loading/Loading";
import { Header } from "./components/Header/Header";
import { usePainelTempoReal } from "./usePainelTempoReal";

import { useRef } from "react";

import * as HoverCard from "@radix-ui/react-hover-card";

export default function PainelTempoReal() {
  const {
    isLoading,
    data,
    setDate,
    date,
    dayEntrada,
    dayPreparo,
    daySaida,
    dayDiversos,
  } = usePainelTempoReal();

  // Criar refs para cada seção
  const entradasRef = useRef(null);
  const saidasRef = useRef(null);
  const preparoRef = useRef(null);
  const ligaRef = useRef(null);
  const diversosRef = useRef(null);

  if (isLoading) {
    return <Loading />;
  }

  // Função para rolar até a seção usando o ref
  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Container>
      <Header setDate={setDate} date={date} />
      {data && (
        <>
          <EntradasPainel
            refProp={entradasRef}
            data={data}
            day={dayEntrada}
            date={date}
          />
          <SaidasPainel
            refProp={saidasRef}
            data={data}
            day={daySaida}
            date={date}
          />
          <PreparoPainel
            refProp={preparoRef}
            data={data}
            day={dayPreparo}
            date={date}
          />
          <LigaPainel
            refProp={ligaRef}
            data={data}
            day={daySaida}
            date={date}
          />
          <DiversosPainel
            refProp={diversosRef}
            data={data}
            day={dayDiversos}
            date={date}
          />
        </>
      )}
      {/* Botão fixo */}
      <FixedButtonContainer>
        <HoverCard.Root>
          <HoverCard.Trigger asChild>
            <ScrollButton>☰</ScrollButton>
          </HoverCard.Trigger>
          <HoverCard.Content side="top" align="end">
            <HoverContent>
              <ul>
                <li onClick={() => scrollToSection(entradasRef)}>Entradas</li>
                <li onClick={() => scrollToSection(saidasRef)}>Saídas</li>
                <li onClick={() => scrollToSection(preparoRef)}>Preparo</li>
                <li onClick={() => scrollToSection(ligaRef)}>Liga</li>
                <li onClick={() => scrollToSection(diversosRef)}>Diversos</li>
              </ul>
            </HoverContent>
          </HoverCard.Content>
        </HoverCard.Root>
      </FixedButtonContainer>
    </Container>
  );
}
