import styled from "styled-components";

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  h2 {
    margin: 0 auto;
  }

  button {
    margin-left: auto;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 14px;
      height: 14px;
    }
  }
`;

export const Form = styled.form`
  background-color: var(--blue-a1);
  padding: 1rem;
  border-radius: 0.25rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  h2 {
    text-align: center;
  }
`;

export const DateInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: bold;
  }
`;

export const StyledDateInput = styled.input.attrs({ type: "date" })`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  border: 1px solid var(--gray-c2);
  border-radius: 4px;
  padding: 4px 8px;
  padding-right: 18px; /* Espaço para o ícone */
  color: var(--blue-a18);
  font-size: 16px;
  outline: none;
  cursor: pointer;

  /* Hover */
  &:hover {
    border-color: var(--blue-emsoft);
  }

  /* Foco */
  &:focus {
    border-color: var(--blue-a4);
    box-shadow: 0 0 5px var(--blue-a9);
  }

  /* Customiza o ícone padrão do input (calendário) */
  &::-webkit-calendar-picker-indicator {
    background: transparent;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    filter: invert(25%) sepia(80%) saturate(300%) hue-rotate(180deg); /* Altera a cor */
  }
`;

// Wrapper com um ícone customizado
export const CalendarIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: var(--gray-c2);
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const Button = styled.button`
  padding: 0.4rem;
  background-color: var(--blue-a18);
  color: var(--blue-a1);
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  border-radius: 0.25rem;
  border: none;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: var(--blue-a15);
  }
`;

export const ButtonReset = styled.button`
  padding: 0.35rem;
  background-color: var(--blue-a2);
  color: var(--blue-a18);
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  border-radius: 0.25rem;
  border: 1px solid var(--gray-c2);
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: var(--blue-a3);
  }
`;
