import { useState, useEffect, useMemo } from "react";
import ChartTotal from "../../../../../../components/Charts/ChartArm/ChartAnalises/ChartTotal";
import { Container } from "./style";

export function Graphic({ data }) {
  const [widthSize, setWidthSize] = useState(window.innerWidth);

  // Função para criar datasets do gráfico
  const createDatasets = (entrada, saida, preparo, liga) => [
    {
      type: "bar",
      label: "Entrada",
      backgroundColor: "#1ecfd6",
      data: entrada,
    },
    {
      type: "bar",
      label: "Saída",
      backgroundColor: "#003d73",
      data: saida,
    },
    {
      type: "bar",
      label: "Preparo",
      backgroundColor: "#c05640",
      data: preparo,
    },
    {
      type: "bar",
      label: "Liga",
      backgroundColor: "#edd170",
      data: liga,
    },
  ];

  // Memoização para evitar cálculos repetidos
  const dataGraphic = useMemo(() => {
    if (!data || data.length === 0) {
      return { datasets: [], labels: [], datacards: [] };
    }

    const total = {
      entrada: [],
      saida: [],
      preparo: [],
      liga: [],
    };

    const totalsByType = {
      entrada: {},
      saida: {},
      preparo: {},
      liga: {},
    };

    let labels = [];

    // Processar labels e totais
    labels = data.reduce((acc, item) => {
      if (item.NOM_MODALIDADE && !acc.includes(item.NOM_MODALIDADE)) {
        acc.push(item.NOM_MODALIDADE);
      }
      return acc;
    }, []);

    // Adicionar "NÃO INFORMADO" se houver dados sem modalidade
    if (data.some((item) => item.NOM_MODALIDADE === null)) {
      labels.push("NÃO INFORMADO");
    }

    // Inicializar os contadores
    labels.forEach((label) => {
      totalsByType.entrada[label] = 0;
      totalsByType.saida[label] = 0;
      totalsByType.preparo[label] = 0;
      totalsByType.liga[label] = 0;
    });

    // Calcular totais
    data.forEach((item) => {
      const label = item.NOM_MODALIDADE || "NÃO INFORMADO";
      if (item.FLG_MOVIMENTO === "E")
        totalsByType.entrada[label] += item.NUM_SACAS;
      if (item.FLG_MOVIMENTO === "S")
        totalsByType.saida[label] += item.NUM_SACAS;
      if (item.FLG_MOVIMENTO === "P")
        totalsByType.preparo[label] += item.NUM_SACAS;
      if (item.FLG_MOVIMENTO === "R")
        totalsByType.liga[label] += item.NUM_SACAS;
    });

    // Preencher os arrays de totais
    labels.forEach((label) => {
      total.entrada.push(totalsByType.entrada[label] || 0);
      total.saida.push(totalsByType.saida[label] || 0);
      total.preparo.push(totalsByType.preparo[label] || 0);
      total.liga.push(totalsByType.liga[label] || 0);
    });

    const datasets = createDatasets(
      total.entrada,
      total.saida,
      total.preparo,
      total.liga
    );

    return { labels, datasets, datacards: [] };
  }, [data]);

  // Atualizar tamanho da tela
  useEffect(() => {
    const handleResize = () => setWidthSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container>
      <div className="diario">
        <ChartTotal
          datasetGraphic={dataGraphic}
          height="500"
          width="1200"
          titleTooltip="Embalagens"
          displayDataLabel={false}
          responsive={widthSize}
        />
      </div>
    </Container>
  );
}
