export function getUserId(array) {
  let valorAposPipe = null;

  for (const chave in array[0]) {
    if (array[0].hasOwnProperty(chave) && chave.includes("|")) {
      valorAposPipe = array[0][chave].split("|")[1];
      break; // Se encontrou o valor, saia do loop
    }
  }
  return valorAposPipe;
}

export function generateId() {
  const timestamp = Date.now(); // Timestamp atual
  const randomNum = Math.random().toString(36).substring(2, 8); // Números aleatórios convertidos para base 36
  const uniqueId = `${timestamp}_${randomNum}`; // Combinação do timestamp com o número aleatório
  return uniqueId;
}
