import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .analises-calendar {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .analises-dates {
    display: flex;
    flex-direction: column;
    width: 130px;
    font-size: 0.9rem;
  }

  // estilização dos botões de atualizar, 30 dias e 7 dias
  .buttonAtt,
  .buttonLast30,
  .buttonLast7 {
    border: none;
    appearance: none;
    background: none;
    outline: none;
    padding-top: 0.5rem;
  }
`;
