import { Route } from "react-router-dom";
import RastreioLotes from "../../Modules/Armazem/Analises/RastreioLotes/RastreioLotes";
import { InstrucaoServicos } from "../../Modules/Armazem/Analises/InstrucaoServicos/InstrucaoServicos";
import { ProcessosFinalizados } from "../../Modules/Armazem/Analises/ProcessosFinalizados/ProcessosFinalizados";
import { PesagemRodoviaria } from "../../Modules/Armazem/Analises/PesagemRodoviaria/PesagemRodoviaria";
import { CargaDescarga } from "../../Modules/Armazem/Analises/CargaDescarga/CargaDescarga";

export function AnaliseRoute() {
  return (
    <>
      <Route
        element={<CargaDescarga />}
        path="armazem/analises/carga-descarga"
        exact
      />
      <Route
        element={<PesagemRodoviaria />}
        path="armazem/analises/pesagem-rodoviaria"
        exact
      />
      <Route
        element={<ProcessosFinalizados />}
        path="armazem/analises/finalizados"
        exact
      />
      <Route
        element={<InstrucaoServicos />}
        path="armazem/analises/isd"
        exact
      />
      <Route
        element={<RastreioLotes />}
        path="armazem/analises/rastreio"
        exact
      />
    </>
  );
}
