import { useState, useContext, useEffect, useMemo } from "react";
import { AnaliseContext } from "../../../../../../contexts/Analise/AnaliseContext";
import { Graphic } from "../Graphic";
import { useRandomColors } from "../../../hooks/useRandomColors";

export function PesagemPorEmbalagem({ data }) {
  const [dataSets, setDataSets] = useState({ datasets: [], labels: [] });
  const { dscControls } = useContext(AnaliseContext);

  const colors = useRandomColors(dscControls.length);

  const processedData = useMemo(() => {
    if (!data.length) return { labels: [], datasets: [] };

    const labels = ["Big Bag", "Sacaria", "Granel", "Outros"];
    const totalGeral = dscControls.reduce((acc, control) => {
      acc[control] = [0, 0, 0, 0];
      return acc;
    }, {});

    data.forEach((item) => {
      if (totalGeral[item.DSC_CONTROLE]) {
        if (item.NUM_BIGBAG === 1) totalGeral[item.DSC_CONTROLE][0] += 1;
        if (item.NUM_SACARIA === 1) totalGeral[item.DSC_CONTROLE][1] += 1;
        if (item.NUM_GRANEL === 1) totalGeral[item.DSC_CONTROLE][2] += 1;
        if (item.NUM_OUTRAS === 1) totalGeral[item.DSC_CONTROLE][3] += 1;
      }
    });

    const datasets = dscControls.map((control, index) => ({
      type: "bar",
      label: control,
      backgroundColor: colors[index],
      data: totalGeral[control],
    }));

    return { labels, datasets };
  }, [data, dscControls, colors]);

  useEffect(() => {
    setDataSets(processedData);
  }, [processedData]);

  return (
    <div>
      <h3>Quantidade de pesagem por embalagem</h3>
      <Graphic height="370" width="580" datasetGraphic={dataSets} />
    </div>
  );
}
