import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0.6rem;
  grid-area: main;
  overflow-y: auto;
  display: grid;

  h2 {
    margin-bottom: 16px;
  }
`;

export const Container = styled.div`
  position: relative;
  float: left;
  background: var(--blue-a18);
  border-radius: 4px;
  padding: 0.6rem;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--blue-a1);

  button {
    background: var(--blue-a1);
    padding: 2px 4px 2px 4px;
    color: #000;
    font-weight: bold;
  }

  table {
    border-collapse: collapse;
  }

  table td,
  table th {
    padding: 0.5rem;
    font-size: 0.8rem;
    text-align: center;
  }
  table tr:nth-child(even) {
    background-color: var(--blue-a12);
  }

  table th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    text-align: start;
    background-color: var(--blue-a4);
    color: var(--blue-a18);
    font-size: 0.975rem;
    font-weight: 700;
    text-align: center;
  }

  .number_page {
    position: relative;
    padding: 1.1rem 0.75rem 0.25rem 0.25rem;
    float: left;
    font-size: 0.75rem;
  }

  .go_page {
    position: relative;
    float: right;
    padding: 0.8rem 0.75rem 0.25rem 0.25rem;
    font-size: 0.75rem;
  }

  .go_page_button {
    position: relative;
    background-color: var(--blue-a1);
    color: var(--blue-a18);
    font-size: 12px;
    border-radius: 4px;
    border: none;
    height: 24px;
    width: 80px;
    padding-left: 0.25rem;
  }

  .pagination {
    display: flex;
    position: relative;
    justify-content: center;
    padding-top: 12px;
  }

  .display_pages {
    border-radius: 4px;
    height: 24px;
    width: 96px;
    background: var(--blue-a1);
    color: var(--blue-a18);
    font-size: 12px;
  }

  .page_direction {
    margin-right: 32px;
  }

  .page_direction button {
    height: 24px;
    :nth-child(1) {
      width: 40px;
      background-color: var(--blue-a1);
      border-radius: 4px;
      color: var(--blue-a18);
      border: none;
      margin-right: 5px;
    }
    :nth-child(2) {
      width: 80px;
      background-color: var(--blue-a1);
      border-radius: 4px;
      color: var(--blue-a18);
      border: none;
      margin-right: 2px;
    }
    :nth-child(3) {
      width: 80px;
      background-color: var(--blue-a1);
      border-radius: 4px;
      color: var(--blue-a18);
      border: none;
    }
    :nth-child(4) {
      width: 40px;
      background-color: var(--blue-a1);
      border-radius: 4px;
      color: var(--blue-a18);
      border: none;
      margin-left: 5px;
    }
  }

  .loadingTable {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px;
  }

  @media (max-width: 750px) {
    table th {
      font-size: 0.7rem;
    }

    @media (max-width: 650px) {
      overflow-x: scroll;
      table th {
        font-size: 0.7rem;
      }
      .pagination_media {
        display: flex;
        flex-wrap: wrap;
      }
      .display_pages {
        display: none;
      }
      .page_direction {
        margin: 0;
      }
      .information_page {
        width: 575px;
      }
    }
  }
`;
