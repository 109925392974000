import { TooltipCustom } from "../../../../components/Tooltip/TooltipCustom";
import { generateId } from "../../../../utils/functions";
import { formatNumber } from "../../../../utils/regex";
import { NoData } from "../components/NoData/NoData";
import { PercentageBar } from "../components/PercentageBar/PercentageBar";
import { PercentageSacas } from "../components/PercentageSacas/PercentageSacas";
import { Title } from "../components/Title/Title";
import {
  Container,
  HeaderSection,
  Table,
  StatusCircle,
  ContainerBarGraphic,
} from "../style";
import { DespResGraphic } from "./DespResGraphic";

export function PreparoPainel({ refProp, data, day, date }) {
  const dataPreparo = data
    .filter((item) => item.FLG_MOVIMENTO === "P")
    .map((item) => ({
      ...item,
      id: generateId(),
    }));

  const totalSacas = dataPreparo.reduce((acc, item) => acc + item.NUM_SACAS, 0);

  if (dataPreparo.length === 0) {
    return <NoData tipo="Preparo" day={day} date={date} refTipo={refProp} />;
  }

  return (
    <Container ref={refProp}>
      <HeaderSection $bgColor="#00A7D0">
        <Title tipo="Preparo" day={day} date={date} totalSacas={totalSacas} />
      </HeaderSection>
      <Table>
        <thead>
          <tr>
            <th />
            <th>Previsão</th>
            <th>Nome</th>
            <th>Quantidade</th>
            <th>DOC</th>
            <th>Status</th>
            <th>OS</th>
            <th>Serviço</th>
            <th>Despejo</th>
            <th>Desp/Res</th>
            <th>Resultado</th>
            <th>Final preparo</th>
            <th>Sacas Armazém</th>
          </tr>
        </thead>
        <tbody>
          {dataPreparo.map((item) => {
            return (
              <tr key={item.id}>
                <td>
                  <StatusCircle $bgColor={item.NOM_COR_STATUS} />
                </td>
                <td>{item.DAT_PREVISAO}</td>
                <td>{item.NOM_CLIENTE}</td>
                <td>{formatNumber(item.NUM_SACAS)}</td>
                <td>{item.NUM_DOCUMENTO}</td>
                <td
                  style={{
                    color: `${item.NOM_COR_STATUS}`,
                    fontWeight: "bold",
                  }}
                >
                  {item.NOM_STATUS}
                </td>
                <td>{item.NUM_OS}</td>
                <td>{item.NOM_SERVICO}</td>
                <td>
                  <PercentageSacas
                    percentage={item.NUM_SACAS_DESPEJO}
                    numSacas={item.NUM_SACAS}
                  />
                </td>
                <td>
                  <TooltipCustom
                    content={
                      <DespResGraphic
                        data={[
                          item.NUM_SACAS_DESPEJO,
                          item.NUM_SACAS_RESULTADO,
                        ]}
                        total={item.NUM_SACAS}
                      />
                    }
                  >
                    <ContainerBarGraphic>
                      <PercentageBar
                        progress={item.NUM_SACAS_DESPEJO}
                        total={item.NUM_SACAS}
                        indicatorColor="#00751F"
                      />
                      <PercentageBar
                        progress={item.NUM_SACAS_RESULTADO}
                        total={item.NUM_SACAS}
                        indicatorColor="#009FE3"
                      />
                    </ContainerBarGraphic>
                  </TooltipCustom>
                </td>
                <td>
                  <PercentageSacas
                    percentage={item.NUM_SACAS_RESULTADO}
                    numSacas={item.NUM_SACAS}
                  />
                </td>
                <td>{item.DAT_FINAL_PREPARO}</td>
                <td>{item.NOM_SACAS_ARMAZEM}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
}
