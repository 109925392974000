import { useContext, useMemo, useState, useEffect } from "react";
import moment from "moment";
import { AnaliseContext } from "../../../../../../contexts/Analise/AnaliseContext";
import { dateToEn } from "../../../../../../utils/dates";
import { Graphic } from "../Graphic";

export function RetornoPorEmbalagem({ data }) {
  const [dataSets, setDataSets] = useState({ datasets: [], labels: [] });
  const { date } = useContext(AnaliseContext);

  const filteredData = useMemo(() => {
    if (!data.length) return [];

    return data.filter((item) =>
      moment(item.DAT_PESO_INI).isBetween(
        moment(dateToEn(date.dataAnaliseInicio)),
        moment(dateToEn(date.dataAnaliseFinal)),
        "date",
        "[]"
      )
    );
  }, [data, date]);

  const processedData = useMemo(() => {
    if (!filteredData.length) return { labels: [], datasets: [] };

    const labels = ["Big Bag", "Sacaria", "Granel", "Outros"];
    const total = {
      retornada: Array(4).fill(0),
      naoRetornada: Array(4).fill(0),
    };

    filteredData.forEach((item) => {
      const embTypes = [
        "NUM_BIGBAG",
        "NUM_SACARIA",
        "NUM_GRANEL",
        "NUM_OUTRAS",
      ];
      embTypes.forEach((type, index) => {
        if (item[type] === 1) {
          total[item.NUM_RETORNA_EMB === 1 ? "retornada" : "naoRetornada"][
            index
          ]++;
        }
      });
    });

    const datasets = [
      {
        type: "bar",
        label: "Retornadas",
        backgroundColor: "#047857",
        data: total.retornada,
      },
      {
        type: "bar",
        label: "Não Retornadas",
        backgroundColor: "#be123c",
        data: total.naoRetornada,
      },
    ];

    return { labels, datasets };
  }, [filteredData]);

  useEffect(() => {
    setDataSets(processedData);
  }, [processedData]);

  return (
    <div>
      <h3>Retorno por embalagem</h3>
      <Graphic height="370" width="1200" datasetGraphic={dataSets} />
    </div>
  );
}
