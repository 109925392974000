import { Container, HeaderSection } from "../../style";

export function NoData({ tipo, day, date, refTipo }) {
  return (
    <Container ref={refTipo}>
      <HeaderSection $bgColor="#fff">
        <h2>
          Sem ocorrência de {tipo} em {day}{" "}
          {date.entrada.flag !== "D"
            ? date.entrada.flag === "A"
              ? "ou anterior"
              : "ou posterior"
            : ""}
        </h2>
      </HeaderSection>
    </Container>
  );
}
