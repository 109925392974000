import { TooltipCustom } from "../../../../../components/Tooltip/TooltipCustom";
import { Circle, Container } from "./styles";

export function PercentageGraphic({ percentage, numSacas }) {
  const percentageSacas = `${(
    (Number(percentage || 0) / Number(numSacas || 0)) *
    100
  ).toFixed(1)}%`;

  return (
    <Container>
      <TooltipCustom content={`${numSacas} (${percentageSacas})`}>
        <Circle percentage={percentage} />
      </TooltipCustom>
      <div>{percentage || "0"}</div>
      <div>{percentageSacas}</div>
    </Container>
  );
}
