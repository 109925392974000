import styled from "styled-components";

export const Container = styled.div`
  .charts {
    background-color: var(--blue-a18);
    padding: 0.5rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
