import { useContext, useEffect, useState } from "react";

import { AnaliseContext } from "../../../../contexts/Analise/AnaliseContext";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";

import { getDataAnalisePesagem } from "../../../../services/api";

import { Container } from "./style";

import { HeaderAnalise } from "../components/HeaderAnalise";
import { Cards } from "./components/Cards";
import Loading from "../../../../components/Layouts/Loading/Loading";
import { PesoPorDia } from "./components/PesoPorDia";
import { RetornoPorEmbalagem } from "./components/RetornoPorEmbalagem";
import { PesagemPorEmbalagem } from "./components/PesagemPorEmbalagem";
import { PesagemPorCaminhao } from "./components/PesagemPorCaminhao";

export function PesagemRodoviaria() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { date } = useContext(AnaliseContext);
  const { dono, userAuth, password, codEmpresa, codFilial } =
    useContext(AuthContext);

  useEffect(() => {
    async function fetchPesagem() {
      setLoading(true);
      const response = await getDataAnalisePesagem(
        dono,
        userAuth,
        password,
        codEmpresa,
        codFilial,
        "DMAH",
        date.dataAnaliseInicio,
        date.dataAnaliseFinal
      );

      setData(response);
      setLoading(false);
    }
    fetchPesagem();
  }, [date, dono, userAuth, password, codEmpresa, codFilial]);

  return (
    <Container>
      <HeaderAnalise title="Pesagem Rodoviária" />
      {loading ? (
        <Loading />
      ) : (
        <>
          <Cards data={data} />
          <div className="content">
            <PesoPorDia data={data} />
            <RetornoPorEmbalagem data={data} />
            <div className="dubble">
              <PesagemPorEmbalagem data={data} />
              <PesagemPorCaminhao data={data} />
            </div>
          </div>
        </>
      )}
    </Container>
  );
}
