import styled from "styled-components";

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2rem;
  border-bottom: 1px solid black;
  background-color: var(--blue-a4);

  h2 {
    font-weight: 400;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;
