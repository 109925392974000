import { useEffect, useState, useContext } from "react";

import { AuthContext } from "../../../contexts/Auth/AuthContext";

import { tableApi } from "../../../services/api";

import { Container } from "./style";
// Importtação de Componentes
import CardArm from "../../../components/Cards/CardArm/CardArm";
import { Table } from "../../../components/Tables/TablesArm/Table";
import DateModal from "../../../components/Modal/ModalArm/DateModal/DateModal";
import Loading from "../../../components/Layouts/Loading/Loading";
import ButtonCalendar from "../../../components/Layouts/ButtonCalendar/ButtonCalendar";

// importação dos icones
import entradaIcon from "../../../assets/Icons Box/icon-entrada.svg";
import saidaIcon from "../../../assets/Icons Box/icon-saida.svg";
import preparoIcon from "../../../assets/Icons Box/icon-preparo.svg";
import reensaqueIcon from "../../../assets/Icons Box/icon-reensaque.svg";

// variavel que retorna a data do dia
const dateToday = new Date().toLocaleDateString();

export default function DashboardArm() {
  const { dono, userAuth, password, codEmpresa, codFilial } =
    useContext(AuthContext);

  const [dataTable, setDataTable] = useState([]);
  const [statusModalCalendar, setStatusModalCalendar] = useState(false);
  const [hiddenCalendar, setHiddenCalendar] = useState();
  const [date, setDate] = useState({});
  const [loading, setLoading] = useState(true);
  const [sacas, setSacas] = useState({
    entradas: "",
    saidas: "",
    preparos: "",
    reensaques: "",
  });

  // variavel recebe as datas do DateModal
  const {
    firstEntrada,
    lastEntrada,
    firstSaida,
    lastSaida,
    firstPreparo,
    lastPreparo,
  } = date;

  // função que retorna os dados da api
  async function result() {
    const returnData = await tableApi(
      dono,
      userAuth,
      password,
      firstEntrada,
      lastEntrada,
      firstSaida,
      lastSaida,
      firstPreparo,
      lastPreparo,
      codEmpresa,
      codFilial
    );
    totalRender(returnData);
    setDataTable(returnData);
    setLoading(false);
  }

  useEffect(() => {
    result();
  }, [date]);

  // Função que calcula  o progresso em porcentagem
  function getProgress(realizado, sacas) {
    let progress = 0;
    if (realizado > 0 || sacas > 0) {
      progress = ((realizado / sacas) * 100).toFixed(2);
      return progress;
    }
    return progress;
  }

  // Função que filtra as embalagens repetidas de um array
  function filterEmbalagens(embalagens) {
    const embalagensFiltradas = embalagens.filter((el, i) => {
      return embalagens.indexOf(el) === i;
    });

    return embalagensFiltradas;
  }

  // Função que retorna a quantidade de sacas por embalagens
  function countEmbalagens(arrModalidade, arrSacas) {
    const newArr = filterEmbalagens(arrModalidade);
    const sacasEmbalagens = [];
    for (let i = 0; i < newArr.length; i++) {
      let count = 0;
      arrSacas.map((item) => {
        if (item.emb === newArr[i]) {
          count += item.scs;
        }
        return item;
      });
      sacasEmbalagens.push(count);
    }
    return sacasEmbalagens;
  }

  // Função que retorna os dados para os cards e modal
  function totalRender(dataTable) {
    let countDocEntrada = 0;
    let countDocSaida = 0;
    let countDocPreparo = 0;
    let countDocReensaque = 0;
    let countReEntrada = 0;
    let countReSaida = 0;
    let countRePreparo = 0;
    let countReReensaque = 0;
    let countDespejoPreparo = 0;
    const modalidade = {
      entrada: [],
      saida: [],
      reensaque: [],
    };

    const qtEmbalagens = {
      entrada: [],
      saida: [],
      reensaque: [],
    };

    let entrada = 0;
    let saida = 0;
    let preparo = 0;
    let reensaque = 0;

    // Estruta para separar os dados de cada modalidade
    dataTable.map((item) => {
      switch (item.FLG_MOVIMENTO) {
        case "E":
          entrada += +item.NUM_SACAS;
          countDocEntrada += 1;
          countReEntrada += item.NUM_PERC_DESCARGA;
          modalidade.entrada.push(
            item.NOM_MODALIDADE === null ? "Não Informado" : item.NOM_MODALIDADE
          );
          qtEmbalagens.entrada.push({
            emb:
              item.NOM_MODALIDADE === null
                ? "Não Informado"
                : item.NOM_MODALIDADE,
            scs: item.NUM_SACAS,
          });
          break;

        case "S":
          saida += item.NUM_SACAS;
          countDocSaida += 1;
          countReSaida += item.NUM_PERC_DESCARGA;
          modalidade.saida.push(
            item.NOM_MODALIDADE === null ? "Não Informado" : item.NOM_MODALIDADE
          );
          qtEmbalagens.saida.push({
            emb:
              item.NOM_MODALIDADE === null
                ? "Não Informado"
                : item.NOM_MODALIDADE,
            scs: item.NUM_SACAS,
          });
          break;

        case "P":
          preparo += item.NUM_SACAS;
          countDocPreparo += 1;
          countDespejoPreparo += item.NUM_SACAS_DESPEJO;
          countRePreparo += item.NUM_SACAS_RESULTADO;
          break;

        case "R":
          reensaque += item.NUM_SACAS;
          countDocReensaque += 1;
          countReReensaque += item.NUM_SACAS_RESULTADO;
          modalidade.reensaque.push(
            item.NOM_MODALIDADE === null ? "Não Informado" : item.NOM_MODALIDADE
          );
          qtEmbalagens.reensaque.push({
            emb:
              item.NOM_MODALIDADE === null
                ? "Não Informado"
                : item.NOM_MODALIDADE,
            scs: item.NUM_SACAS,
          });
          break;

        case "L":
          countReSaida += item.NUM_PERC_DESCARGA;
          break;

        default:
          break;
      }
      return item;
    });
    setSacas({
      entradas: [
        entrada,
        countDocEntrada,
        countReEntrada,
        getProgress(countReEntrada, entrada),
        filterEmbalagens(modalidade.entrada),
        countEmbalagens(modalidade.entrada, qtEmbalagens.entrada),
      ],
      saidas: [
        saida,
        countDocSaida,
        countReSaida,
        getProgress(countReSaida, saida),
        filterEmbalagens(modalidade.saida),
        countEmbalagens(modalidade.saida, qtEmbalagens.saida),
      ],
      preparos: [
        preparo,
        countDocPreparo,
        countRePreparo,
        getProgress(countRePreparo, preparo),
        ["AUTORIZADO", "DESPEJADO", "RESULTADO"],
        [preparo, countDespejoPreparo, countRePreparo],
      ],
      reensaques: [
        reensaque,
        countDocReensaque,
        countReReensaque,
        getProgress(countReReensaque, reensaque),
        filterEmbalagens(modalidade.reensaque),
        countEmbalagens(modalidade.reensaque, qtEmbalagens.reensaque),
      ],
    });
  }

  // Função que abre o Modal do Calendário
  const handleShowModalCalendar = () => {
    setStatusModalCalendar(true);
    setHiddenCalendar(true);
  };

  // Função que oculta o calendário
  const handleRequestCloseCalendar = () => {
    setHiddenCalendar(false);
  };

  return (
    <>
      <Container>
        <DateModal
          handleShowModalCalendar={statusModalCalendar}
          handleRequestCloseCalendar={handleRequestCloseCalendar}
          hiddenCalendar={hiddenCalendar}
          setDate={setDate}
        />
        <div className="icon-calendar">
          <ButtonCalendar handleShowModalCalendar={handleShowModalCalendar} />
          <div className="information-calendar">
            <span>
              Embarque:{" "}
              {!firstEntrada ? "Todas até " + dateToday : firstEntrada + " a"}{" "}
              {lastEntrada}
            </span>
            <span>
              Desembarque:{" "}
              {!firstSaida ? "Todas até " + dateToday : firstSaida + " a"}{" "}
              {lastSaida}
            </span>
            <span>
              Industrialização:{" "}
              {!firstPreparo ? "Todas até " + dateToday : firstPreparo + " a"}{" "}
              {lastPreparo}
            </span>
          </div>
        </div>
        <div className="main_cards">
          <CardArm
            icon={entradaIcon}
            data={sacas.entradas}
            title="Desembarque"
            color={"#00751F"}
            loading={loading}
          />
          <CardArm
            icon={saidaIcon}
            data={sacas.saidas}
            title="Embarque"
            color={"#6B0009"}
            loading={loading}
          />
          <CardArm
            icon={preparoIcon}
            data={sacas.preparos}
            title="Industrialização"
            color={"#986D00"}
            loading={loading}
          />
          <CardArm
            icon={reensaqueIcon}
            data={sacas.reensaques}
            title="Reensaque"
            color={"#030F14"}
            loading={loading}
          />
        </div>
        <div className="main_table">
          {loading && <Loading />}
          {!loading && <Table dataTable={dataTable} loading={loading} />}
        </div>
      </Container>
    </>
  );
}
