import { useMemo } from "react";

export function useRandomColors(length) {
  return useMemo(() => {
    const fixedColors = [
      "#06b6d4",
      "#047857",
      "#be123c",
      "#003d73",
      "#c05640",
      "#edd170",
    ];

    const randomColors = Array.from(
      { length },
      () =>
        `rgb(${Math.floor(Math.random() * 255)}, 
           ${Math.floor(Math.random() * 255)}, 
           ${Math.floor(Math.random() * 255)})`
    );

    return [...fixedColors, ...randomColors];
  }, [length]);
}
