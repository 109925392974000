import styled from "styled-components";

// Wrapper do container do radio button
export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 0.7rem 0;
  border-bottom: 1px solid var(--gray-c2);
`;

// Label estilizada
export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-size: 16px;
  color: var(--blue-a18);

  &:hover {
    color: var(--blue-emsoft);
  }
`;

// Input Radio escondido
export const HiddenRadio = styled.input.attrs({ type: "radio" })`
  display: none;
`;

// Custom Radio Button
export const StyledRadio = styled.span`
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid var(--gray-c2);
  border-radius: 50%;
  position: relative;

  ${HiddenRadio}:checked + & {
    border-color: var(--blue-emsoft);
  }

  /* Circulo preenchido ao selecionar */
  ${HiddenRadio}:checked + &::after {
    content: "";
    width: 10px;
    height: 10px;
    background-color: var(--blue-emsoft);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* Hover */
  ${RadioLabel}:hover & {
    border-color: var(--blue-emsoft);
  }
`;
