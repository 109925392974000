export function Title({ tipo = "Entrada", day, totalSacas, date }) {
  const tipoLower = tipo === "Liga/Reensaque" ? "preparo" : tipo.toLowerCase();

  return (
    <h2>
      {tipo} em {day}{" "}
      {date[tipoLower].flag !== "D"
        ? date[tipoLower].flag === "A"
          ? "ou anterior"
          : "ou posterior"
        : ""}{" "}
      - {totalSacas} Sacas
    </h2>
  );
}
