import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.6rem;

  .table {
    margin-top: 1rem;
    width: 1200px;
    align-self: center;
  }
`;
