import { useState } from "react";
import ReactModal from "react-modal";
import {
  Button,
  ButtonReset,
  CalendarIcon,
  DateInputWrapper,
  Form,
  StyledDateInput,
  WrapperButtons,
  WrapperTitle,
} from "./styles";

import { initialState } from "../../usePainelTempoReal";

import iconClosed from "../../../../../assets/Icons-Modal/icon-closed-a18.svg";

import { RadioWrapper } from "../RadioModalidade/styles";
import { RadioModalidade } from "../RadioModalidade/RadioModalidade";

export function DateModalRealTime({
  setDate,
  date,
  handleRequestCloseCalendar,
  statusModalCalendar,
}) {
  const [formData, setFormData] = useState(date);

  // Função para atualizar o estado conforme a data ou a opção selecionada
  const handleChange = (field, key, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        [key]: value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setDate(formData);
    handleRequestCloseCalendar();
  };

  const handleInitialState = () => {
    setFormData(initialState);
    setDate(initialState);
    handleRequestCloseCalendar();
  };

  return (
    <ReactModal
      className="modal"
      isOpen={statusModalCalendar}
      onRequestClose={handleRequestCloseCalendar}
      ariaHideApp={false}
      style={{
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        },
        content: {
          WebkitOverflowScrolling: "touch",
        },
      }}
    >
      <Form onSubmit={handleSubmit}>
        <WrapperTitle>
          <h2>Alterar datas</h2>
          <button type="button" onClick={handleRequestCloseCalendar}>
            <img src={iconClosed} alt="Imagem de fechar" />
          </button>
        </WrapperTitle>
        <div>
          <DateInputWrapper>
            <span>Entrada:</span>
            <StyledDateInput
              type="date"
              value={formData.entrada.day}
              onChange={(e) => handleChange("entrada", "day", e.target.value)}
              required
            />
            <CalendarIcon>📆</CalendarIcon>
          </DateInputWrapper>

          <RadioWrapper>
            <RadioModalidade
              name="entradaFlag"
              value="A"
              checked={formData.entrada.flag === "A"}
              onChange={() => handleChange("entrada", "flag", "A")}
              modalidade="anterior"
            />
            <RadioModalidade
              name="entradaFlag"
              value="D"
              checked={formData.entrada.flag === "D"}
              onChange={() => handleChange("entrada", "flag", "D")}
              modalidade="na data"
            />

            <RadioModalidade
              name="entradaFlag"
              value="P"
              checked={formData.entrada.flag === "P"}
              onChange={() => handleChange("entrada", "flag", "P")}
              modalidade="posterior"
            />
          </RadioWrapper>
        </div>

        <div>
          <DateInputWrapper>
            <span>Saída:</span>
            <StyledDateInput
              type="date"
              value={formData.saida.day}
              onChange={(e) => handleChange("saida", "day", e.target.value)}
              required
            />
            <CalendarIcon>📆</CalendarIcon>
          </DateInputWrapper>
          <RadioWrapper>
            <RadioModalidade
              name="saidaFlag"
              value="A"
              checked={formData.saida.flag === "A"}
              onChange={() => handleChange("saida", "flag", "A")}
              modalidade="anterior"
            />
            <RadioModalidade
              name="saidaFlag"
              value="D"
              checked={formData.saida.flag === "D"}
              onChange={() => handleChange("saida", "flag", "D")}
              modalidade="na data"
            />
            <RadioModalidade
              name="saidaFlag"
              value="P"
              checked={formData.saida.flag === "P"}
              onChange={() => handleChange("saida", "flag", "P")}
              modalidade="posterior"
            />
          </RadioWrapper>
        </div>

        <div>
          <DateInputWrapper>
            <span>Preparo:</span>
            <StyledDateInput
              type="date"
              value={formData.preparo.day}
              onChange={(e) => handleChange("preparo", "day", e.target.value)}
              required
            />
            <CalendarIcon>📆</CalendarIcon>
          </DateInputWrapper>
          <RadioWrapper>
            <RadioModalidade
              name="preparoFlag"
              value="A"
              checked={formData.preparo.flag === "A"}
              onChange={() => handleChange("preparo", "flag", "A")}
              modalidade="anterior"
            />
            <RadioModalidade
              name="preparoFlag"
              value="D"
              checked={formData.preparo.flag === "D"}
              onChange={() => handleChange("preparo", "flag", "D")}
              modalidade="na data"
            />
            <RadioModalidade
              name="preparoFlag"
              value="P"
              checked={formData.preparo.flag === "P"}
              onChange={() => handleChange("preparo", "flag", "P")}
              modalidade="posterior"
            />
          </RadioWrapper>
        </div>

        <div>
          <DateInputWrapper>
            <span>Diversos:</span>
            <StyledDateInput
              type="date"
              value={formData.diversos.day}
              onChange={(e) => handleChange("diversos", "day", e.target.value)}
              required
            />
            <CalendarIcon>📆</CalendarIcon>
          </DateInputWrapper>
          <RadioWrapper>
            <RadioModalidade
              name="diversosFlag"
              value="A"
              checked={formData.diversos.flag === "A"}
              onChange={() => handleChange("diversos", "flag", "A")}
              modalidade="anterior"
            />
            <RadioModalidade
              name="diversosFlag"
              value="D"
              checked={formData.diversos.flag === "D"}
              onChange={() => handleChange("diversos", "flag", "D")}
              modalidade="na data"
            />
            <RadioModalidade
              name="diversosFlag"
              value="P"
              checked={formData.diversos.flag === "P"}
              onChange={() => handleChange("diversos", "flag", "P")}
              modalidade="posterior"
            />
          </RadioWrapper>
        </div>

        <WrapperButtons>
          <ButtonReset type="button" onClick={handleInitialState}>
            Limpar
          </ButtonReset>
          <Button type="submit">Alterar</Button>
        </WrapperButtons>
      </Form>
    </ReactModal>
  );
}
