import { AnaliseContext } from "./AnaliseContext";
import { useContext, useState, useEffect } from "react";

import { getDataSqlJson } from "../../services/api";
import { AuthContext } from "../Auth/AuthContext";
import { dayTodayFormated, lastSevenDayFormated } from "../../utils/dates";

export const AnaliseProvider = ({ children }) => {
  const { userAuth, password, dono } = useContext(AuthContext);
  const [dscControls, setDscControls] = useState([]);
  const [date, setDate] = useState({
    dataAnaliseInicio: lastSevenDayFormated,
    dataAnaliseFinal: dayTodayFormated,
  });

  useEffect(() => {
    const getSqlJsonDSC = async () => {
      const comandSql = `SELECT ${dono}.fu_retira_acento(DSC_CONTROLE) DSC_CONTROLE FROM ${dono}.CCF_TCLP_TPCTL_PORTARIA`;
      try {
        const data = await getDataSqlJson(userAuth, password, comandSql);
        if (data && data.length > 0) {
          const newData = data.map((item) => item.DSC_CONTROLE);
          setDscControls(newData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (userAuth) {
      getSqlJsonDSC();
    }
  }, [userAuth, password, dono]);

  return (
    <AnaliseContext.Provider value={{ dscControls, date, setDate }}>
      {children}
    </AnaliseContext.Provider>
  );
};
