import styled from "styled-components";

export const Container = styled.div`
  max-width: 1200px;
  align-self: center;

  .diario {
    background-color: var(--blue-a18);
    border: 2px solid var(--blue-a16);
    border-radius: 8px;
  }
`;
