import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 100%;
    text-align: center;
    padding: 0px 2px;
  }

  div:first-child {
    border-right: 1px solid #595959;
  }

  div:last-child {
    /* border-left: 1px solid #595959; */
  }
`;
