import { useMemo } from "react";
import { Container } from "./style";
import CardAnalisePesagemRodoviaria from "../../../../../../components/Cards/CardArmAnalises/CardPesagemRodoviaria/CardAnalisePesagemRodoviaria";

export function Cards({ data }) {
  const card = useMemo(() => {
    let qtdSacasCarga = 0,
      qtdSacasDescarga = 0,
      qtdSacasOutros = 0;
    let qtdPesagemCarga = 0,
      qtdPesagemDescarga = 0,
      qtdPesagemOutros = 0;

    if (data && data.length > 0) {
      data.forEach((item) => {
        const descricao = item.DSC_CONTROLE?.toUpperCase().split(" ") || [];
        if (descricao.includes("CARGA")) {
          qtdSacasCarga += Number(item.NUM_SACAS);
          qtdPesagemCarga++;
        } else if (descricao.includes("DESCARGA")) {
          qtdSacasDescarga += Number(item.NUM_SACAS);
          qtdPesagemDescarga++;
        } else {
          qtdSacasOutros += Number(item.NUM_SACAS);
          qtdPesagemOutros++;
        }
      });
    }

    const totalSacas = qtdSacasCarga + qtdSacasDescarga + qtdSacasOutros;
    const percentCarga = totalSacas
      ? Number(((qtdSacasCarga * 100) / totalSacas).toFixed(2))
      : 0;
    const percentDescarga = totalSacas
      ? Number(((qtdSacasDescarga * 100) / totalSacas).toFixed(2))
      : 0;
    const percentOutros = totalSacas
      ? Number(((qtdSacasOutros * 100) / totalSacas).toFixed(2))
      : 0;

    return {
      totalSacas,
      qtdSacasCarga,
      qtdSacasDescarga,
      qtdSacasOutros,
      qtdPesagemCarga,
      qtdPesagemDescarga,
      qtdPesagemOutros,
      percentCarga,
      percentDescarga,
      percentOutros,
    };
  }, [data]);

  const percentArray = [
    card.percentCarga,
    card.percentDescarga,
    card.percentOutros,
  ];

  return (
    <Container>
      <CardAnalisePesagemRodoviaria
        nameCard="CARGAS"
        totalSacas={card.qtdSacasCarga}
        totalPesagens={card.qtdPesagemCarga}
        percent={card.percentCarga}
        percentArray={percentArray}
        classCard={card.percentCarga > 0 ? "cardCarga" : ""}
      />
      <CardAnalisePesagemRodoviaria
        nameCard="DESCARGAS"
        totalSacas={card.qtdSacasDescarga}
        totalPesagens={card.qtdPesagemDescarga}
        percent={card.percentDescarga}
        percentArray={percentArray}
        classCard={card.percentDescarga > 0 ? "cardDescarga" : ""}
      />
      <CardAnalisePesagemRodoviaria
        nameCard="OUTROS"
        totalSacas={card.qtdSacasOutros}
        totalPesagens={card.qtdPesagemOutros}
        percent={card.percentOutros}
        percentArray={percentArray}
        classCard={card.percentOutros > 0 ? "cardTerceiro" : ""}
      />
    </Container>
  );
}
