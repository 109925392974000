import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --blue-a1: #F1F3F4;
    --blue-a2: #ECF0F2;
    --blue-a3: #E1E9EC;
    --blue-a4: #9FCDE1;
    --blue-a5: #83C6E3;
    --blue-a6: #64BDE3;
    --blue-a7: #4CB7E4;
    --blue-a8: #28ADE5;
    --blue-a9: #009FE3;
    --blue-a10: #086996;
    --blue-a11: #004D7A;
    --blue-a12: #083D61;
    --blue-a13: #0F304A;
    --blue-a14: #0B2D48;
    --blue-a15: #08273F;
    --blue-a16: #052238;
    --blue-a17: #031A2D;
    --blue-a18: #01121F;

    --gray-c1: #EBEBEB;
    --gray-c2: #BFBFBF;
    --gray-c3: #949494;
    --gray-c4: #616161;
    --gray-c5: #2E2E2B;
    --gray-c6: #141414;

    --green-entry: #00751F;
    --red-output: #6B0009;
    --yellow-preparation: #986D00;
    --blue-repack: #030F14;
    --blue-emsoft: #009FE3;
  }

  body{ 
    background-color: var(--blue-a3);

  }

  h1,h2,h3,h4,h5 {
    -webkit-font-smoothing: antialiased;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Poppins, Oxygen, sans-serif;
  
  }

  
  button {
    cursor: pointer;
  }
  Link {
    color: inherit;
    text-decoration: none;
  }
  a {
    color: inherit;
    text-decoration: none;
  }

//ANCHOR Estilização da Scrollbar (Barra de rolagem)
  ::-webkit-scrollbar {
  width: 5px;
  height: 6px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--blue-a18);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--blue-a18);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--blue-a18);
  }

//ANCHOR Estilização da Navbar, Sidebar e Main (definindo local onde elas irão ficar na página)
  .container {
    background: var(--blue-a3);
    display: grid;
    height: 100vh;
    /* width: 100vw; */
    grid-template-columns: 0.2fr 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas: 
    'sidebar nav nav nav'
    'sidebar main main main'
  }

  @media (max-width: 2560px) {
    .container {
      grid-template-columns: 1fr;
      grid-template-areas: 
      'nav nav nav'
      'main main main'
  }
}

`;
