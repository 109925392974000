import styled from "styled-components";
import * as Progress from "@radix-ui/react-progress";

// Estilos para o container (Progress.Root)
export const StyledProgressRoot = styled(Progress.Root)`
  position: relative;
  overflow: hidden;
  background-color: var(--gray-c2); /* Cor de fundo da barra */
  border-radius: 4px;
  width: 100%;
  height: 12px;
`;

// Estilos para o indicador (Progress.Indicator)
export const StyledProgressIndicator = styled(Progress.Indicator)`
  background-color: ${(props) =>
    props.indicatorColor || "#4caf50"}; /* Cor personalizada */
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease; /* Animação suave */
`;
