import styled from "styled-components";

// Componente para a bolinha
export const Circle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: conic-gradient(
      ${(props) => `#057bca ${props.percentage}%`},
      ${(props) => `#d40a36 ${props.percentage}%`}
    );
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  div {
    text-align: center;
  }

  div:first-child {
    border-right: 1px solid #595959;
    width: 24px;
  }

  div:nth-child(2) {
    flex: 1;
    padding: 0px 1px;
  }

  div:last-child {
    border-left: 1px solid #595959;
    padding-left: 1px;
    flex: 1;
  }
`;
