import { useContext, useMemo } from "react";

import { AnaliseContext } from "../../../../../../contexts/Analise/AnaliseContext";

import { useRandomColors } from "../../../hooks/useRandomColors";
import { useGraphicResponsive } from "../../../hooks/useGraphicResponsive";

import { Container } from "./style";

import ChartTotal from "../../../../../../components/Charts/ChartArm/ChartAnalises/ChartTotal";

export function SacasControleTipoCaminhao({ data }) {
  const { dscControls } = useContext(AnaliseContext);
  const widthSize = useGraphicResponsive();
  // Geração de cores otimizadas com useMemo
  const colors = useRandomColors(dscControls.length);

  // Memoização da lógica de cálculo dos datasets
  const dataSets = useMemo(() => {
    if (!data.length) return { datasets: [], labels: [] };

    const totalGeral = {};

    dscControls.forEach((controls) => {
      totalGeral[controls] = [0, 0, 0]; // [totalCacamba, totalGraneleiro, totalOutros]

      data.forEach((item) => {
        if (item.DSC_CONTROLE === controls) {
          if (item.NUM_CACAMBA_CAM === 1)
            totalGeral[controls][0] += item.NUM_SACAS || 1;
          if (item.NUM_GRANELEIRO_CAM === 1)
            totalGeral[controls][1] += item.NUM_SACAS || 1;
          if (item.NUM_OUTROS_CAM === 1)
            totalGeral[controls][2] += item.NUM_SACAS || 1;
        }
      });
    });

    const labels = ["Caçamba", "Graneleiro", "Outros"];

    const datasets = dscControls.map((controls, index) => ({
      type: "bar",
      label: controls,
      backgroundColor: colors[index],
      data: totalGeral[controls],
    }));

    return { labels, datasets };
  }, [data, dscControls, colors]);

  return (
    <Container>
      <h3>Sacas por controle e tipos de caminhão</h3>
      <div className="charts">
        <ChartTotal
          datasetGraphic={dataSets}
          height="420"
          width="1280"
          titleTooltip="Sacas"
          displayDataLabel={true}
          responsive={widthSize}
        />
      </div>
    </Container>
  );
}
