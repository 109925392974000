import { CustomAppRoute } from "./CustomAppRoute";
import { HomeAppProdutorRoute } from "./HomeAppProdutorRoute";
import { ModulosMenuRoute } from "./ModulosMenu";
import { NotificacoesRoute } from "./NotificacoesRoute";
import { QuadroAvisosRoute } from "./QuadroAvisosRoute";
import { VinculaProdutoresRoute } from "./VinculaProdutoresRoute";

export function AppProdutorRoutes() {
  return (
    <>
      {HomeAppProdutorRoute()}
      {VinculaProdutoresRoute()}
      {NotificacoesRoute()}
      {CustomAppRoute()}
      {ModulosMenuRoute()}
      {QuadroAvisosRoute()}
    </>
  );
}
